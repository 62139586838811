import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button/Button";
import TextBox from "../../../components/TextBox/TextBox";
import FXBOrderConfirmationDetail from "../../../components/OrderConfirmationDetail/FXBOrderConfirmationDetail";
import Select from "react-select";
import { useAppSelector } from "../../../Redux/app/hooks";
import WhiteLoader from "../../../assets/loader-white.gif";
import {
  is_rfq_access,
  trading_portfolios,
  is_allow_execution_and_settlement,
  portfoliosIds,
  update_is_socket_connected,
} from "../../../Redux/features/Dashboard/DashBoardSlice";

import {
  fetchSecurities,
  getVendorList,
  getOrderTypes,
  getOrderTimeTypes,
  placeOrder,
  placeFxspotOrder,
  getFXQuoteRequest,
  requestRfq,
  cancelRFQ,
  getFees,
  limits_key,
  markupOpt_key,
  vendorsoptions_key,
  bond_vendor_option_key,
  clientMarkupData_key,
  // vendor_option_key,
  is_show_vendor_dropdown_key,
  show_bond_vendor_dropdown_key,
  fund_order_types_key,
  days_key,
  rfq_timer_key,
  responsePlaceOrder_key,
  responseplaceFxspotOrder_key,
  responsegetFXQuoteRequest_key,
  responserequestRfq_key,
  responseCancelRFQ_key,
  responseGetFees_key,
  updateresponsePlaceOrder,
  update_rfq_form_data,
  update_form_data,
  add_form_allocates_inputs,
  update_form_allocates_inputs,
  update_client_markup_data,
  update_map_allocates_inputs,
  remove_form_allocates_inputs,
  clear_form_allocates_inputs,
  updateBuyBtnDisabled,
  updateSellBtnDisabled,
  update_tradeweb_quotes_stream_channel_data,
  clear_tradeweb_quotes_stream_channel_data,
  show_forex_vendor_dropdown_key,
  forex_vendor_option_key,
  getClientMarkup,
} from "../../../Redux/features/OrderSection/OrderSectionSlice";
import {
  name,
  bid_price,
  ask_price,
  last_price,
  rate,
  marketId,
  update_prices,
  set_is_quote_price,
  update_ask_prices,
  update_bid_prices,
  update_send_new_sec_req,
  live_update_candle_chart_data,
  clear_candle_chart_data,
} from "../../../Redux/features/ChartData/ChartDataSlice";
import { useAppDispatch } from "../../../Redux/app/hooks";
import {
  formatNumber,
  calculate_percentage,
  removeCommaFromString,
  DropDownStyles2,
  price_format,
  DropDownStylesBroker,
  validQuantity,
  formatPrice,
} from "../../../helpers/helper";
import OrderConfirmationModal from "../../../components/OrderConfirmationModal/OrderConfirmationModal";
import AutoCompleteSearchBox from "../../../components/AutoCompleteSearchBox/AutoCompleteSearchBox";
import InformationWindow from "../../InformationWindow/InformationWindow";
import Eyeicon from "../../../assets/EyeIcon.svg";

import addMore from "../../../assets/plus.svg";
import removeOne from "../../../assets/minus.svg";
import { string } from "yup";
import { number } from "yup/lib/locale";
import Loading from "../../../components/Loading/Loading";
import { useOnClickOutside } from "../../../CustomHooks/useOnClickOutside";
import askIcon from "../../../assets/red-arrow.svg";
import bidIcon from "../../../assets/green-arrow.svg";
import closeIcon from "../../../assets/close-icon.svg";
import { is_update_transaction_data } from "../../../Redux/features/Orders/OrderListingSlice";
import socket from "../../../socket";
import Svg from "../../../components/Svg/Svg";
import { updateIsShowAddtoWatchList } from "../../../Redux/features/MainDashboard/MainDashBoardSlice";
import HandleFormValidation from "../../../components/Validation/Validation";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FXSOrderConfirmationDetail from "../../../components/OrderConfirmationDetail/FXSOrderConfirmationDetail";
import FXSIOrderConfirmationDetail from "../../../components/OrderConfirmationDetail/FXSIOrderConfirmationDetail";
import FXBIOrderConfirmationDetail from "../../../components/OrderConfirmationDetail/FXBIOrderConfirmationDetail";
import EQBOrderConfirmationDetail from "../../../components/OrderConfirmationDetail/EQBOrderConfirmationDetail";
import EQSOrderConfirmationDetail from "../../../components/OrderConfirmationDetail/EQSOrderConfirmationDetail";
import BondBOrderConfirmationDetail from "../../../components/OrderConfirmationDetail/BondBOrderConfirmationDetail";
import BondSOrderConfirmationDetail from "../../../components/OrderConfirmationDetail/BondSOrderConfirmationDetail";
import FundSOrderConfirmationDetail from "../../../components/OrderConfirmationDetail/FundSOrderConfirmationDetail";
import FundBOrderConfirmationDetail from "../../../components/OrderConfirmationDetail/FundBOrderConfirmationDetail";
function OrderSection(props: any) {
  const { width, height } = props;
  const dispatch = useAppDispatch();

  const [logged_user_id, set_logged_user_id] = useState(0);

  const [bond_quote_ask_vendor, set_bond_quote_ask_vendor] = useState(0);
  const [bond_quote_bid_vendor, set_bond_quote_bid_vendor] = useState(0);
  const [is_tradeweb_received, set_is_tradeweb_received] = useState(false);
  const [bond_quote_ask_size, set_bond_quote_ask_size] = useState(0);
  const [bond_quote_bid_size, set_bond_quote_bid_size] = useState(0);
  const [principal_amount, set_principal_amount] = useState(0);
  const [isconfirmClose, setIsConfirmClose] = useState(false);
  const [isclearForm, setIsClearForm] = useState(false);
  const [is_call_get_fees, set_is_call_get_fees] = useState(true);
  const [formName, setFormName] = useState("finle-buy-btn");
  const [is_btn_txt_change, set_is_btn_txt_change] = useState(false);
  const [order_message, set_order_message] = useState("");
  const [message_type, set_message_type] = useState("");
  const [rfq_global_response, set_rfq_global_response] = useState<any>({});
  const [hit_btn_label, set_hit_btn_label] = useState("Hit");
  const [lift_btn_label, set_lift_btn_label] = useState("Lift");
  const [order_executed_lbl, set_order_executed_lbl] = useState("");
  const [is_order_placed, set_is_order_placed] = useState(false);
  const [quote_not_found, set_iquote_not_found] = useState("");
  const [is_timer_start, set_is_timer_start] = useState(false);
  const [is_channel_subscribe, set_is_channel_subscribe] = useState(false);
  const [isin_code, set_isin_code] = useState("");
  const [add_blink, set_add_blink] = useState(false);
  const [is_blinking, set_is_blinking] = useState(false);
  const [requesting_quotes_title, set_requesting_quotes_title] = useState("Requesting Quotes");
  const [first_timer_dialog, set_first_timer_dialog] = useState(false);
  const [is_stream_stop, set_is_stream_stop] = useState(false);
  const [rfq_channel_name, set_rfq_channel_name] = useState("");
  const [convert_rfq_form, set_convert_rfq_form] = useState(false);
  const [hideNoResults, set_hideNoResults] = useState(true);
  const [order_confirm_dialog, set_order_confirm_dialog] = useState(false);
  const [fees, set_fees] = useState(0);
  const [addedWathlistTitle, set_addedWathlistTitle] = useState("Added in watchlist");
  const [addToWathlistTitle, set_addToWathlistTitle] = useState("Add to watchlist");
  const [displayWatchList, set_displayWatchList] = useState(false);
  const [disabled, set_disabled] = useState(true);
  // const [buy_btn_disabled, set_buy_btn_disabled] = useState(true);
  // const [sell_btn_disabled, set_sell_btn_disabled] = useState(true);
  const [disabled_confirm, set_disabled_confirm] = useState(1);
  const [getquote_disabled_confirm, set_getquote_disabled_confirm] = useState(false);
  const [confirm_btn_labl, set_confirm_btn_labl] = useState("Submit");
  const [security_title, set_security_title] = useState("{Security Title}");
  const [priceFieldState, set_priceFieldState] = useState(false);
  const [expireDateFieldState, set_expireDateFieldState] = useState(false);
  const [stopPriceFieldState, set_stopPriceFieldState] = useState(false);
  const [quantityFieldState, set_quantityFieldState] = useState(true);
  const [amountFieldState, set_amountFieldState] = useState(false);
  const [displayQuantityFieldState, set_displayQuantityFieldState] = useState(false);
  const [displayShortSellState, set_displayShortSellState] = useState(false);
  const [is_global_fees_display, set_is_global_fees_display] = useState(0);
  const [is_submitclickStart, set_is_submitclickStart] = useState(false);
  const [is_confirm_set, set_is_confirm_set] = useState(false);
  const [confirm, set_confirm] = useState({
    account_name: null,
    amount: 0,
    security_name: null,
    isin: null,
    direction: null,
    type: "MARKET",
    quantity: 0,
    price: 0,
    fees: 0,
    net_consideration: 0,
    time: "day",
    currency: "",
  });

  const [fund_order_types, set_fund_order_types] = useState([]);

  const [qtyCssClass, set_qtyCssClass] = useState("w-full");
  const [net_consideration, set_net_consideration] = useState(0);
  const [loading, set_loading] = useState(false);
  const [is_fees_display, set_is_fees_display] = useState(0);
  const [is_commission, set_is_commission] = useState(0);
  const [search_table, set_search_table] = useState([{ security_name: null }]);
  const [qty_label, set_qty_label] = useState("Shares");
  const [isRFQForm, set_isRFQForm] = useState(false);
  const [rfqFormType, set_rfqFormType] = useState("bond");
  const [rfq_limit_price, set_rfq_limit_price] = useState<any>("");
  const [timer_request_rfq, set_timer_request_rfq] = useState({
    bid_price: 0,
    ask_price: 0,
  });
  const [rfq_current_step, set_rfq_current_step] = useState(1);
  const [socketCounter, set_socketCounter] = useState(1);
  const [orderexecutedid, set_orderexecutedid] = useState("");
  const [amountLabel, set_amountLabel] = useState("Amount");
  const [disableRFQBtn, set_disableRFQBtn] = useState(true);
  const [disableRFQForm, set_disableRFQForm] = useState(false);
  const [disableCurrencyTwo, set_disableCurrencyTwo] = useState(true);
  const [cancelButton, set_cancelButton] = useState(false);
  const [buyButton, set_buyButton] = useState(false);
  const [sellButton, set_sellButton] = useState(false);
  const [rfqButton, set_rfqButton] = useState(true);
  const [zero_price_link, set_zero_price_link] = useState(false);
  const [currencies, set_currencies] = useState([]);
  const [selectedCurrencies, set_selectedCurrencies] = useState([]);
  const [settlementTypes, set_settlementTypes] = useState(["TODAY", "TOMORROW", "SPOT"]);
  const [directions, set_directions] = useState(["BUY", "SELL"]);
  const [timer, set_timer] = useState(60);
  const [rfqLabel, set_rfqLabel] = useState("RFQ");
  const [isRfqBtn, set_isRfqBtn] = useState(true);

  const [search_security, set_search_security] = useState(null);
  const [searching_security, set_searching_security] = useState(null);
  const [search_items, set_search_items] = useState([]);
  const [isLoading, set_isLoading] = useState(false);
  const [vendors, set_vendors] = useState(["Interactive", "Instinet"]);
  // const [showVendor, set_showVendor] = useState(true);
  const [gtd_expire_date, set_gtd_expire_date] = useState(false);
  const [show, setShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [order_status, set_order_status] = useState("");
  const [rfqrequestid, set_rfqrequestid] = useState<any>("");
  const default_security_set: any = useAppSelector((state) => state.dashBoard.default_security_set);
  const send_new_sec_req: any = useAppSelector((state) => state.chartdata.send_new_sec_req);
  const is_load_security: any = useAppSelector((state) => state.dashBoard.is_load_security);
  const is_load_security_message: any = useAppSelector((state) => state.dashBoard.is_load_security_message);
  const rfq_form = useAppSelector((state) => state.orderSection.rfq_form_data);
  const form = useAppSelector((state) => state.orderSection.form_data);
  const allocates_inputs = useAppSelector((state) => state.orderSection.form_allocates_inputs);
  const buy_btn_disabled = useAppSelector((state) => state.orderSection.buy_btn_disabled);
  const sell_btn_disabled = useAppSelector((state) => state.orderSection.sell_btn_disabled);
  const newAllocatesInputs = allocates_inputs;
  const get_quote_api_status: any = useAppSelector((state) => state.chartdata.quote_status);
  const full_security_name: any = useAppSelector((state) => state.dashBoard.full_security_name);

  const show_forex_vendor_dropdown: any = useAppSelector(show_forex_vendor_dropdown_key);
  const forex_vendor_option: any = useAppSelector(forex_vendor_option_key);
  const vendorsoptions: any = useAppSelector(vendorsoptions_key);
  // const vendor_option: any = useAppSelector(vendorsoptions_key);
  const bond_vendor_option: any = useAppSelector(bond_vendor_option_key);
  const showVendor: any = useAppSelector(is_show_vendor_dropdown_key);
  const show_bond_vendor_dropdown: any = useAppSelector(show_bond_vendor_dropdown_key);
  const [showVendorOption, setShowVendorOption] = useState(false);
  const [security_id, setSecurityId] = useState(default_security_set.security_id);
  const place_order_status = useAppSelector((state) => state.orderSection.place_order_status);
  const rfq_place_order_status = useAppSelector((state) => state.orderSection.rfq_place_order_status);
  const clientVendors = useAppSelector((state) => state.orderSection.clientVendors);
  const loggedType = useAppSelector((state) => state.mainDashBoard.loggedType);
  const fx_rfq_quote_data = useAppSelector((state) => state.orderSection.fx_rfq_quote_data);
  const quote_status = useAppSelector((state) => state.chartdata.quote_status);
  const [market_id, setMarketId] = useState(default_security_set.market_id);
  const name_value: any = useAppSelector(name);
  const ask_value: any = useAppSelector(ask_price);
  const bid_value: any = useAppSelector(bid_price);
  const rate_value: any = useAppSelector(rate);
  const current_marek_id: any = useAppSelector(marketId);
  const last_price_value: any = useAppSelector(last_price);
  const limits: any = useAppSelector(limits_key);
  const markupOpt: any = useAppSelector(markupOpt_key);
  // const [markupOpt, setMarkupOpt] = useState({
  //   abs: "abs",
  //   bps: "bps",
  // });
  const fund_order_types_arr: any = useAppSelector(fund_order_types_key);
  const rfq_timer: any = useAppSelector(rfq_timer_key);
  const days: any = useAppSelector(days_key);
  const responsePlaceOrder: any = useAppSelector(responsePlaceOrder_key);
  const responseplaceFxspotOrder: any = useAppSelector(responseplaceFxspotOrder_key);
  const clientMarkupData: any = useAppSelector(clientMarkupData_key);
  const responseFXQuoteOrder: any = useAppSelector(responsegetFXQuoteRequest_key);
  const responserequestRfq: any = useAppSelector(responserequestRfq_key);
  const responseCancelRFQ: any = useAppSelector(responseCancelRFQ_key);

  const responseGetFees: any = useAppSelector(responseGetFees_key);
  const allocate_portfolio: any = useAppSelector(trading_portfolios);
  const isAllowExecutionAndSettlement: any = useAppSelector(is_allow_execution_and_settlement);
  const selectedPortfolios: any = useAppSelector(portfoliosIds);
  const isrfqaccess: any = useAppSelector(is_rfq_access);
  const bPrice = useAppSelector((state) => state.chartdata.bid_price);
  const aPrice = useAppSelector((state) => state.chartdata.ask_price);
  const [changeQty, setChangeQty] = useState("");
  let inputButtonElement: any = useRef(null);
  const place_order_order = useAppSelector((state) => state.orderSection.place_order_loading);
  // START update price
  useEffect(() => {
    if (Number(form.is_calculate) === 1 && order_confirm_dialog && form.order_type.toLowerCase() === "market") {
      if (form.direction.toLowerCase() === "buy") {
        set_confirm({
          ...confirm,

          price: ask_value,
        });
      } else {
        set_confirm({
          ...confirm,

          price: bid_value,
        });
      }
      socket.emit("mark_up_calculation", form);
    }

    dispatch(update_form_data({ ask: ask_value, bid: bid_value, last_price: last_price_value, rate: rate_value, vendor: form.vendor }));
    localStorage.setItem("current_security_id", default_security_set.security_id);
    localStorage.setItem("current_market_id", default_security_set.market_id);
    if (Number(market_id) === 8) {
      set_displayWatchList(true);
    } else {
      set_displayWatchList(false);
    }

    // console.log("2407--------[ok]---2", form.order_type.toLowerCase());
    let cMid: any = localStorage.getItem("current_market_id");
    if (Number(cMid) !== 1 && Number(cMid) !== 6) {
      if (Number(bPrice) !== 0 || Number(aPrice) !== 0) {
        if (form.order_type.toLowerCase() === "market") {
          // console.log("2407--------[ok]---3", Number(form.quantity));
          if (Number(form.quantity) !== 0) {
            // console.log("2407--------[ok]---4", Number(form.quantity));

            // set_buy_btn_disabled(false);
            // set_sell_btn_disabled(false);
            dispatch(updateBuyBtnDisabled(false));
            dispatch(updateSellBtnDisabled(false));
          }
        } else if (form.order_type.toLowerCase() === "limit") {
          if (Number(form.quantity) !== 0 && Number(form.price) !== 0) {
            // set_buy_btn_disabled(false);
            // set_sell_btn_disabled(false);
            dispatch(updateBuyBtnDisabled(false));
            dispatch(updateSellBtnDisabled(false));
          }
        } else if (form.order_type.toLowerCase() === "stop") {
          if (Number(form.quantity) !== 0 && Number(form.stop_price) !== 0) {
            // set_buy_btn_disabled(false);
            // set_sell_btn_disabled(false);
            dispatch(updateBuyBtnDisabled(false));
            dispatch(updateSellBtnDisabled(false));
          }
        } else if (form.order_type.toLowerCase() === "stop_limit") {
          if (Number(form.quantity) !== 0 && Number(form.price) !== 0 && Number(form.stop_price) !== 0) {
            // set_buy_btn_disabled(false);
            // set_sell_btn_disabled(false);
            dispatch(updateBuyBtnDisabled(false));
            dispatch(updateSellBtnDisabled(false));
          }
        }
      }
    }
    if (Number(cMid) === 5 && bid_value === 0 && ask_value === 0) {
      set_isRfqBtn(true);
    } else {
      set_isRfqBtn(false);
    }
  }, [ask_value, bid_value, last_price_value, rate_value, current_marek_id, rfq_form.vendor]);
  //END update price
  useEffect(() => {
    // console.log("send_new_sec_req", send_new_sec_req);
    if (send_new_sec_req) {
      let send_new_sec = localStorage.getItem("send_new_sec");
      if (send_new_sec) {
        let securityList = [];
        securityList = [
          {
            security_id: 0,
            conid: 0,
            isin_code: send_new_sec,
            market_id: 5,
            exch: "",
            currency: "",
            reqvid: 8,
          },
        ];
        var securitySet = {
          security_list: securityList,
          // security_list: Number(localStorage.getItem("login-id")) === 2 ? user_securityList : client_user_securityList,
          socket_id: socket.id,
          from: "web",
          user_id: localStorage.getItem("login-id"),
        };
        socket.emit("security_subscription", securitySet);
      }
    }
  }, [send_new_sec_req]);

  useEffect(() => {
    if (get_quote_api_status === "success") {
      if (form.order_type === "market" || form.order_type === "market_close") {
        set_priceFieldState(false);
        set_stopPriceFieldState(false);
        set_qtyCssClass("w-full");
      } else if (form.order_type === "stop") {
        set_priceFieldState(false);
        set_stopPriceFieldState(true);
        set_qtyCssClass("");
      } else if (form.order_type === "stop_limit") {
        set_priceFieldState(true);
        set_stopPriceFieldState(true);
        set_qtyCssClass("w-full");
      } else {
        set_priceFieldState(true);
        set_stopPriceFieldState(false);
      }
      dispatch(set_is_quote_price(false));
    }
  }, [get_quote_api_status]);

  const [stockSymbolList, setStockSymbolList] = useState(["AAPL"]);
  const [cashSymbolList, setCashSymbolList] = useState(["EURUSD"]);
  const [isUpdateHandleSSEForex, setisUpdateHandleSSEForex] = useState(false);
  const [quoteForex, setQuoteForex] = useState({});
  const [isIexStreamStatus, set_isIexStreamStatus] = useState(false);
  const [default_market_id, set_default_market_id] = useState("6");
  const [is_market_id_change, set_is_market_id_change] = useState(false);
  useEffect(() => {
    (async () => {
      if (quote_status === "loading") {
        set_loading(false);
      } else {
        set_loading(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [quote_status]);
  useEffect(() => {
    (async () => {
      if (is_market_id_change) {
        // console.log("set_default_market_id-------", String(default_security_set.market_id));
        set_default_market_id(String(default_security_set.market_id));
        set_is_market_id_change(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [is_market_id_change]);
  // const lineTimeline = useAppSelector((state) => state.chartdata.lineTimeline);
  // useEffect(() => {
  //   // console.log("lineTimeline------", lineTimeline);
  //   let currentMarketId = localStorage.getItem("current_market_id");
  //   if (lineTimeline && Number(currentMarketId) === 5) {
  //     var lineTimelineData = { isin_code: default_security_set.isin_code, line_time_line: lineTimeline, user_id: localStorage.getItem("login-id") };
  //     socket.emit("lineTimeline", lineTimelineData);
  //   }
  //   return () => {
  //     // this now gets called when the component unmounts
  //   };
  //   // eslint-disable-next-line
  // }, [lineTimeline]);

  const [primaryCurrecnyValue, setPrimaryCurrecnyValue] = useState("");
  const [secondaryCurrecnyValue, setSecondaryCurrecnyValue] = useState("");
  const [displayCurrency, setDisplayCurrency] = useState("");
  const [isInverse, setIsInverse] = useState(false);
  useEffect(() => {
    if (displayCurrency) {
      if (displayCurrency === primaryCurrecnyValue) {
        setIsInverse(false);
      } else {
        setIsInverse(true);
      }
    } else {
      setIsInverse(false);
    }
    dispatch(update_form_data({ isInverse: isInverse }));
    // console.log("default_security_set setIsInverse", isInverse);
    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [displayCurrency]);
  const inverceCurrency = (disCurr: any) => {
    if (disCurr === primaryCurrecnyValue) {
      setDisplayCurrency(secondaryCurrecnyValue);
      setIsInverse(true);
    } else {
      setDisplayCurrency(primaryCurrecnyValue);
      setIsInverse(false);
    }
  };
  const [is_load_change, set_is_load_change] = useState<any>("end");
  useEffect(() => {
    let currentMarketId = localStorage.getItem("current_market_id");
    set_is_load_change("start");

    // console.log("default_security_set", default_security_set);
    setShowVendorOption(true);
    if (loggedType === "internal") {
      if (Number(currentMarketId) === 5) {
        dispatch(update_rfq_form_data({ vendor: 8 }));
        localStorage.setItem("current_reqvid_id", "8");
      } else {
        localStorage.setItem("current_reqvid_id", "");
      }
    }
    if (Number(currentMarketId) === 6) {
      const match = default_security_set.iex_symbol.match(/([A-Z]{3})([A-Z]{3})/);
      // console.log("default_security_set match", match);
      if (match) {
        setIsInverse(false);
        const p_c_value = match[1];
        const s_c_value = match[2];
        setPrimaryCurrecnyValue(p_c_value);
        setSecondaryCurrecnyValue(s_c_value);
        setDisplayCurrency(p_c_value);
      }
    } else {
      setPrimaryCurrecnyValue("");
      setSecondaryCurrecnyValue("");
      setDisplayCurrency("");
    }
    if (Number(currentMarketId) === 6 || Number(currentMarketId) === 1) {
      // set_sell_btn_disabled(false);
      // set_buy_btn_disabled(false);
      dispatch(updateBuyBtnDisabled(false));
      dispatch(updateSellBtnDisabled(false));
      let currentSecurityConid = localStorage.getItem("current_security_conid");
      var unSecuritySet = { conid: currentSecurityConid, from: "web", user_id: localStorage.getItem("user_id"), socket_id: socket.id };
      socket.emit("security_unsubscription", unSecuritySet);
    }
    if (Number(default_security_set.market_id) === 8) {
      // set_sell_btn_disabled(true);
      // set_buy_btn_disabled(true);
      dispatch(updateBuyBtnDisabled(false));
      dispatch(updateSellBtnDisabled(false));
    }
    set_is_market_id_change(true);
    // console.log("default_security_set", default_security_set);
    localStorage.setItem("current_security_conid", default_security_set.conid);
    if (Number(default_security_set.security_id) === 443389) {
      localStorage.setItem("current_security_conid", "677037673"); // IBIT equity IB CONID set temp solution
    }
    if ((default_security_set.market_id === 5 || default_security_set.market_id === "5") && isrfqaccess === "yes") {
      socket.emit("tradeweb-quotes");
      set_isRFQForm(true);
      set_rfqFormType("bond");
      set_is_clear_rfq_form(true);
    } else {
      setIsClearForm(true);
      set_isRFQForm(false);
      set_rfqFormType("cash");
    }
    // END RFQ from setting
    if (default_security_set.is_update === true) {
      let counter = 0;
      dispatch(update_form_data({ order_type: "market" }));
      localStorage.setItem("current_security_id", default_security_set.security_id);

      if (default_security_set.market_id !== undefined) {
        setSecurityId(default_security_set.security_id);
        setMarketId(default_security_set.market_id);
        if (Number(default_security_set.market_id) === 8) {
          dispatch(update_form_data({ day: "good_till_cancel", order_type: "market" }));
        }
        if (!isIexStreamStatus) {
          if (loggedType === "internal") {
            if (Number(currentMarketId) === 5) {
              streamChannel(default_security_set, 8);
            } else {
              streamChannel(default_security_set);
            }
          }
        }
      }
    } else {
      if (loggedType === "internal") {
        if (Number(currentMarketId) === 5) {
          streamChannel(default_security_set, 8);
        } else {
          streamChannel(default_security_set);
        }
      }
    }
    if (loggedType === "internal") {
      // in forext disabled vendor dropdown and move trade must on IB
      if (Number(currentMarketId) === 6) {
        // console.log("default_security_set-IN-vendor-update-forex", default_security_set);
        dispatch(update_form_data({ vendor: 2 }));
        setShowVendorOption(false);
      }
      if (Number(currentMarketId) === 1) {
        // console.log("default_security_set-IN-vendor-update", default_security_set);
        dispatch(update_form_data({ vendor: 2 }));
      }
    } else {
      // Client Vendor :: Find the object with the matching market type ID
      if (clientVendors) {
        const foundObject: any = clientVendors.find((item: any) => Number(item.market_type_id) === Number(currentMarketId));
        if (foundObject !== undefined) {
          // console.log("clientVendors:: ", clientVendors);
          // console.log("clientVendors:: foundObject", foundObject);
          // console.log("clientVendors:: vendor", foundObject.vendor_id);
          if (Number(currentMarketId) !== 5) {
            dispatch(update_form_data({ vendor: foundObject.vendor_id }));
          } else {
            dispatch(update_rfq_form_data({ vendor: foundObject.vendor_id }));
          }
          if (Number(currentMarketId) === 5) {
            streamChannel(default_security_set, foundObject.vendor_id);
          } else {
            streamChannel(default_security_set);
          }
          if (Number(currentMarketId) === 5) {
            localStorage.setItem("current_reqvid_id", foundObject.vendor_id);
          } else {
            localStorage.setItem("current_reqvid_id", "");
          }
        }
      }
    }
    if (Number(currentMarketId) === 8) {
      dispatch(update_form_data({ dat: "gtc" }));
    }
    setTimeout(() => {
      set_is_load_change("end");
    }, 200);
    dispatch(live_update_candle_chart_data([]));
    dispatch(clear_candle_chart_data([]));
    //}
  }, [default_security_set]);
  useEffect(() => {
    (async () => {
      if (full_security_name && full_security_name != null) {
        // let textArr = full_security_name.split("|");
        // let isin_code = textArr[1].trim();
        let textArr = full_security_name.split("|");
        if (textArr.length > 1) {
          let isin_code = textArr[1].trim();
        } else {
          let isin_code = textArr[0].trim();
        }
        set_isin_code(isin_code);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [full_security_name]);

  const [change_time, set_change_time] = useState<any>("");

  const streamChannel = (security_data: any, reqvid: any = 0) => {
    if (Number(security_data.market_id) === 1 || Number(security_data.market_id) === 5 || Number(security_data.market_id) === 6) {
      set_change_time(Date.now());
      let securityList = [];
      if (Number(security_data.market_id) === 5) {
        securityList = [
          {
            security_id: security_data.security_id,
            conid: security_data.conid,
            isin_code: security_data.isin_code,
            market_id: security_data.market_id,
            exch: security_data.ib_exchange,
            currency: security_data.currency,
            reqvid: reqvid === 0 ? rfq_form.vendor : reqvid,
            // bp: 0,
            // ap: 0,
          },
        ];
      } else {
        if (Number(security_data.security_id) === 443389) {
          // localStorage.setItem("current_security_conid", "677037673"); // IBIT equity IB CONID set temp solution
          securityList = [
            {
              security_id: security_data.security_id,
              conid: "677037673",
              isin_code: security_data.isin_code,
              market_id: security_data.market_id,
              exch: security_data.ib_exchange,
              currency: security_data.currency,
              // bp: 0,
              // ap: 0,
            },
          ];
        } else {
          securityList = [
            {
              security_id: security_data.security_id,
              conid: security_data.conid,
              isin_code: security_data.isin_code,
              market_id: security_data.market_id,
              exch: security_data.ib_exchange,
              currency: security_data.currency,
              // bp: 0,
              // ap: 0,
            },
          ];
        }
      }

      var securitySet = {
        security_list: securityList,
        // security_list: loggedType === 'internal' ? user_securityList : client_user_securityList,
        socket_id: socket.id,
        from: "web",
        user_id: localStorage.getItem("login-id"),
      };
      socket.emit("security_subscription", securitySet);
    }
  };
  const updatehandleSSEForex = (quote: any) => {
    setQuoteForex(quote);
  };
  const updatehandleSSEStock = (quote: any) => {
    setQuoteForex(quote);
  };
  useEffect(() => {
    let quote: any = quoteForex;
    let iex_symbol = default_security_set.iex_symbol;
    if ((default_security_set.market_id === 1 || default_security_set.market_id === "1") && iex_symbol === quote.symbol) {
      let d = new Date(quote.latestUpdate);
      let bidPprice = quote.iexBidPrice;
      let askPrice = quote.iexAskPrice;
      let last_price = quote.price;
      quote.price = quote.iexRealtimePrice;
      if (quote.iexBidPrice === 0 || quote.iexBidPrice === null || quote.iexBidPrice === undefined) {
        bidPprice = quote.price;
      }
      if (quote.iexAskPrice === 0 || quote.iexAskPrice === null || quote.iexAskPrice === undefined) {
        askPrice = quote.price;
      }
      if (askPrice === null || askPrice === undefined) {
        askPrice = 0;
      }
      if (bidPprice === null || bidPprice === undefined) {
        bidPprice = 0;
      }
      // console.log("boom askPrice------------------", askPrice);
      // console.log("boom bidPprice------------------", bidPprice);
      let quoterate = (Number(askPrice.toFixed(2)) - Number(bidPprice.toFixed(2))).toFixed(2);
      let updateData = {
        bid_price: bidPprice,
        ask_price: askPrice,
        last_price: last_price,
        rate: quoterate,
        symbol: quote.symbol,
      };
      if (iex_symbol === quote.symbol) {
        // console.log("handleSSEStock updateData", updateData);
        // console.log("ðŸš€ ~ useEffect ~ updateData:1", updateData);
        dispatch(update_prices(updateData));
      }
    } else if (default_security_set.market_id === 6 || default_security_set.market_id === "6" || default_security_set.market_id === "cash") {
      // console.log("IEX:: quote.length", Object.keys(quote).length);
      if (Object.keys(quote).length > 0) {
        let iex_symbol = default_security_set.iex_symbol;
        if (iex_symbol === quote.symbol) {
          quote.price = quote.rate;

          let data = calculateBidAsk(quote);
          // console.log("IEX:: In data", data);
          let quoterate = ((Number(data.ask.toFixed(5)) - Number(data.bid.toFixed(5))) * 10000).toFixed(2);
          let updateData = {
            bid_price: data.bid.toFixed(5),
            ask_price: data.ask.toFixed(5),
            last_price: quote.rate.toFixed(5),
            rate: quoterate,
            symbol: quote.symbol,
          };

          // console.log("IEX:: return handle responce", quote);
          // console.log("ðŸš€ ~ useEffect ~ updateData:2", updateData);
          dispatch(update_prices(updateData));
        }
      }
    }
  }, [quoteForex]);
  const calculateBidAsk = (data: any) => {
    let price = +data.rate;
    let ask = 0;
    let bid = 0;
    if (price > 0) {
      ask = price + (price * 0.001) / 100;
      bid = price - (price * 0.001) / 100;
    }
    return { price, ask, bid };
  };
  // onload data manage
  useEffect(() => {
    dispatch(fetchSecurities(default_security_set.security_id));
    dispatch(getOrderTypes());

    dispatch(getOrderTimeTypes(form.order_type));
    let uid = localStorage.getItem("login-id");
    set_logged_user_id(Number(uid));
    if (default_security_set.security_id) {
      localStorage.setItem("current_security_id", default_security_set.security_id);
      dispatch(update_form_data({ security_id: default_security_set.security_id }));
      dispatch(update_rfq_form_data({ security_id: default_security_set.security_id }));
      if ((default_security_set.market_id === 5 || default_security_set.market_id === "5") && isrfqaccess === "yes") {
        set_isRFQForm(true);
        set_rfqFormType("bond");
      }
      // END RFQ from setting
    }
  }, [dispatch]);
  const handleClick = () => {};
  const updateButtonEvent = (e: any) => {
    if (form.fund_order_type === "amount") {
      if (form.fund_amount > 0) {
        set_disabled(false);
      } else {
        set_disabled(true);
      }
    }
  };
  const handlemarkupFeeschange = (e: any, index: any) => {
    if (price_format(e.target.value)) {
      dispatch(
        update_form_allocates_inputs({ index: index, field: "markup_fees", value: e.target.value, mktid: localStorage.getItem("current_market_id") })
      );
      dispatch(
        update_form_allocates_inputs({
          index: index,
          field: "set_fees",
          value: responseGetFees.set_fees,
          mktid: localStorage.getItem("current_market_id"),
        })
      );
      dispatch(
        update_form_allocates_inputs({ index: index, field: "fees", value: responseGetFees.fees, mktid: localStorage.getItem("current_market_id") })
      );
      // dispatch(
      //   update_map_allocates_inputs({
      //     allocates_inputs: allocates_inputs,
      //     markup_fees: e.target.value,
      //     updated_index: index,
      //     form_type: true,
      //     set_fees: responseGetFees.set_fees,
      //     fees: responseGetFees.fees,
      //   })
      // );
    }
  };

  const handleChangeDay = (e: any) => {
    dispatch(update_form_data({ day: e.value }));
  };

  const handleChangeFundQuantity = (e: any) => {
    if (String(e.target.value) === "" || Number(e.target.value) === 0) {
      set_disabled(true);
    }
    let cMid: any = localStorage.getItem("current_market_id");
    if (Number(cMid) === 8) {
      dispatch(update_form_data({ fund_amount: 0 }));
      dispatch(update_form_data({ vendor: 3 }));
      dispatch(update_form_data({ last_price: bPrice }));
      if (last_price_value === 0) {
        // set_buy_btn_disabled(true);
        // set_sell_btn_disabled(true);
        dispatch(updateBuyBtnDisabled(true));
        dispatch(updateSellBtnDisabled(true));
      } else {
        // set_sell_btn_disabled(false);
        // set_buy_btn_disabled(false);
        dispatch(updateBuyBtnDisabled(false));
        dispatch(updateSellBtnDisabled(false));
      }
    }
    let qty = validQuantity(e.target.value);
    if (qty !== false) {
      dispatch(update_form_data({ quantity: qty }));
    }
  };
  const handleChangeQuantity = (e: any) => {
    // console.log(`Bid value === ${bPrice}   ask value ${aPrice}`);
    let qty = formatNumber(String(e.target.value));
    if (qty !== false) {
      dispatch(update_form_data({ quantity: qty }));
    }
  };
  const handleChangeFundAmount = (e: any) => {
    let cMid: any = localStorage.getItem("current_market_id");
    if (Number(cMid) === 8) {
      dispatch(update_form_data({ quantity: 0 }));
      dispatch(update_form_data({ vendor: 3 }));
      dispatch(update_form_data({ last_price: bPrice }));
      if (last_price_value === 0) {
        // set_buy_btn_disabled(true);
        // set_sell_btn_disabled(true);
        dispatch(updateBuyBtnDisabled(true));
        dispatch(updateSellBtnDisabled(true));
      } else {
        // set_sell_btn_disabled(false);
        // set_buy_btn_disabled(false);
        dispatch(updateBuyBtnDisabled(false));
        dispatch(updateSellBtnDisabled(false));
      }
    }
    let amt = formatNumber(String(e.target.value));
    if (amt !== false) {
      // console.log("FUND:: amount", amt);
      dispatch(update_form_data({ fund_amount: amt }));
    }
  };
  const handleChangePrice = (e: any) => {
    if (price_format(e.target.value)) {
      dispatch(update_form_data({ price: e.target.value }));
    }
  };
  const handleChangeStopPrice = (e: any) => {
    if (price_format(e.target.value)) {
      dispatch(update_form_data({ stop_price: e.target.value }));
    }
  };
  const handleChangeDisplayQuantity = (e: any) => {
    dispatch(update_form_data({ display_quantity: e.target.value }));
  };
  // START Update state if arguments change
  useEffect(() => {
    (async () => {
      if (Object.keys(fund_order_types_arr).length > 0) {
        set_fund_order_types(fund_order_types_arr);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [fund_order_types_arr]);
  useEffect(() => {
    (async () => {
      UpdateNetConsideration(rfq_form.quantity);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [rfq_form.quantity]);
  useEffect(() => {
    (async () => {
      UpdateNetConsideration(form.quantity);

      if (form.fund_order_type === "amount") {
        if (Number(form.is_calculate) !== 0) {
          dispatch(
            update_form_allocates_inputs({ index: 0, field: "amount", value: form.fund_amount, mktid: localStorage.getItem("current_market_id") })
          );
          dispatch(update_form_allocates_inputs({ index: 0, field: "percentage", value: 100, mktid: localStorage.getItem("current_market_id") }));
          // dispatch(
          //   update_map_allocates_inputs({
          //     allocates_inputs: allocates_inputs,
          //     form_type: true,
          //     amount: form.fund_amount,
          //     updated_index: 0,
          //   })
          // );
        }

        if (form.fund_amount > 0) {
          set_disabled(false);
        } else {
          set_disabled(true);
        }
      } else {
        dispatch(update_form_allocates_inputs({ index: 0, field: "qty", value: form.quantity, mktid: localStorage.getItem("current_market_id") }));
        dispatch(update_form_allocates_inputs({ index: 0, field: "percentage", value: 100, mktid: localStorage.getItem("current_market_id") }));
        // dispatch(
        //   update_map_allocates_inputs({
        //     allocates_inputs: allocates_inputs,
        //     form_type: true,
        //     qty: form.quantity,
        //     updated_index: 0,
        //   })
        // );
        if (Number(String(form.quantity.replace(/[ ,]+/g, ""))) !== 0 && Number(String(form.quantity.replace(/[ ,]+/g, ""))) != null) {
          set_disabled(false);
        } else {
          set_disabled(true);
        }

        if (Number(form.quantity) === 0) {
          set_disabled(true);
        }
      }
      if (form.order_type === "market" || form.order_type === "market_close") {
        set_priceFieldState(false);
        set_stopPriceFieldState(false);
        set_qtyCssClass("w-full");
      } else if (form.order_type === "stop") {
        set_priceFieldState(false);
        set_stopPriceFieldState(true);
        set_qtyCssClass("");
      } else if (form.order_type === "stop_limit") {
        set_priceFieldState(true);
        set_stopPriceFieldState(true);
        set_qtyCssClass("w-full");
      } else {
        set_priceFieldState(true);
        set_stopPriceFieldState(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [form.quantity, form.price, form.day, form.order_type, form.display_quantity, form.stop_price, form.fund_amount]);
  useEffect(() => {
    (async () => {
      if (form.direction || form.security_id) {
        // console.log("form direction", form.direction);
        // console.log("form security_id", security_id);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [form.direction, form.security_id]);

  // END Update state if arguments change
  const UpdateNetConsideration = (quantity: any) => {
    var selected = selectedPortfolios;

    let validate = 1;
    if (validate) {
      let p_id = 0;
      if (selected.length > 0) p_id = selected[0];
      else p_id = selected[0];
      let s_id: any = localStorage.getItem("current_security_id");
      if (isRFQForm) {
        dispatch(update_rfq_form_data({ security_id: s_id, portfolio_id: p_id }));
      } else {
        dispatch(update_form_data({ security_id: s_id, portfolio_id: p_id }));
      }
      checkButSellBtn(quantity);
    }
  };

  const checkButSellBtn = (quantity: any) => {
    if (form.fund_order_type === "amount") {
      if (form.fund_amount > 0) {
        set_disabled(false);
      } else {
        set_disabled(true);
      }
    } else {
    }
    if (isRFQForm) {
      if (quantityFieldState) {
        // setform({ ...form, quantity: formatNumber(form.quantity) });
      }
      if (amountFieldState) {
        // set_rfq_form({ ...rfq_form, fund_amount: formatNumber(rfq_form.fund_amount) });
      }
      if (priceFieldState) {
        dispatch(update_rfq_form_data({ limit_price: formatNumber(rfq_form.limit_price) }));
      }
    } else {
      if (quantityFieldState) {
        // setform({ ...form, quantity: formatNumber(form.quantity) });
      }
      if (amountFieldState) {
        // setform({ ...form, fund_amount: formatNumber(form.fund_amount) });
        let resNumber = formatNumber(String(form.fund_amount));
        if (resNumber !== false) {
          dispatch(update_form_data({ fund_amount: resNumber }));
        }
      }
      if (priceFieldState) {
        // setform({ ...form, price: formatNumber(form.price) });
        dispatch(update_form_data({ price: form.price }));
      }
      if (stopPriceFieldState) {
        dispatch(update_form_data({ stop_price: form.stop_price }));
      }
      if (displayQuantityFieldState) {
        dispatch(update_form_data({ stop_price: formatNumber(form.stop_price) }));
      }
    }
  };
  const onChangeOrderType = (e: any) => {
    // console.log(e);
    let order_type = e.value;
    dispatch(update_form_data({ order_type: order_type }));
    set_qtyCssClass("");
    if (order_type === "market" || order_type === "market_close") {
      set_priceFieldState(false);
      set_stopPriceFieldState(false);
      set_qtyCssClass("w-full");
    } else if (order_type === "stop") {
      set_priceFieldState(false);
      set_stopPriceFieldState(true);
      set_qtyCssClass("");
    } else if (order_type === "stop_limit") {
      set_priceFieldState(true);
      set_stopPriceFieldState(true);
      set_qtyCssClass("w-full");
    } else {
      set_priceFieldState(true);
      set_stopPriceFieldState(false);
    }
    dispatch(getOrderTimeTypes(e.value));
  };

  const setDirection = (direction: string) => {
    if (form.vendor === 7) {
      set_hide_quote_btn(false);
    } else {
      set_hide_quote_btn(true);
    }
    dispatch(update_form_data({ is_calculate: 1, direction: direction }));
  };
  useEffect(() => {
    (async () => {
      if (isconfirmClose) {
        setIsConfirmClose(!isconfirmClose);
        set_order_confirm_dialog(false);
        const current_market_id = localStorage.getItem("current_market_id");
        let d_vid = 0;
        if (clientVendors) {
          const foundObject: any = clientVendors.find((item: any) => Number(item.market_type_id) === Number(current_market_id));
          if (foundObject !== undefined) {
            if (Number(current_market_id) !== 5) {
              d_vid = foundObject.vendor_id;
            } else {
              d_vid = foundObject.vendor_id;
            }
          }
        }
        if (loggedType === "internal" && d_vid === 0 && Number(current_market_id) === 5) {
          d_vid = 8;
        }
        if (loggedType === "internal" && d_vid === 0 && Number(current_market_id) !== 5) {
          d_vid = 2;
        }
        if (Number(current_market_id) === 5) {
          dispatch(
            update_rfq_form_data({
              trade_way: null,
              timeout_action: 1,
              portfolio_id: 0,
              step: 1,
              rfq_form_type: "bond",
              security_id: Number(localStorage.getItem("current_security_id")),
              is_calculate: 1,
              currencyOne: null,
              currencyTwo: null,
              currency: null,
              settlementType: "SPOT",
              settlement_type: "SPOT",
              settlement_date: "",
              bid_price: 0,
              ask_price: 0,
              bid_size: 0,
              ask_size: 0,
              bid_side: null,
              ask_side: null,
              rate: 0,
              direction: is_zero_link_click ? rfq_form.direction : "",
              side: "",
              quote_side: null,
              //timer: "00:00",
              quantity: "0",
              limit_price: 0,
              bid_entry_id: null,
              ask_entry_id: null,
              symbol: null,
              is_zero_price: false,
              lock_quote: {},
              // rfs_lock_quote: {
              //   bidQuoteId: 0,
              //   askQuoteId: 0,
              //   securityId: "",
              //   dbSecurityId: 0,
              //   bidVendorId: 0,
              //   askVendorId: 0,
              //   bidPrice: 0.0,
              //   bidSize: 0,
              //   askPrice: 0.0,
              //   askSize: 0,
              //   bidTime: "",
              //   askTime: "",
              //   askMsz: 0,
              //   bidMsz: 0,
              //   bidReqvid: 0,
              //   askReqvid: 0,
              // },
              type: "limit",
              day: "day",
              rfq_request_id: "",
              order_executed_id: "",
              allocates_input_fees: 0,
              allocates_input_set_fees: 0,
              allocate_portfolios: [
                {
                  client_id: null,
                  qty: "0",
                  percentage: "",
                  fees: 0,
                  set_fees: 0,
                  markup_fees: 0,
                  amount: 0,
                  markup_type: "abs",
                  markup_value: 0,
                  est_markup: 0,
                  inverse_convert_amt_display: 0,
                  inverse_currency_amt_display: 0,
                  markup_price: 0,
                },
              ],
              filter_client_id: 0,
              filter_client_qty: 0,
              vendor: d_vid,
            })
          );
        } else {
          dispatch(
            update_form_data({
              isConfirm: false,
              isInverse: false,
              ltp_price_data: {},
              fx_quote_data: {},
              order_type: "market",
              fund_order_type: "share",
              fund_amount: 0,
              day: "day",
              quantity: "0",
              price: 0.0,
              stop_price: 0.0,
              portfolio_id: 0,
              bid: 0,
              ask: 0,
              rate: 0,
              last_price: 0,
              direction: "",
              security_id: Number(localStorage.getItem("current_security_id")),
              is_calculate: 1,
              allocate_portfolios: [
                {
                  client_id: null,
                  qty: "0",
                  percentage: "",
                  fees: 0,
                  set_fees: 0,
                  markup_fees: 0,
                  amount: 0,
                  markup_type: "abs",
                  markup_value: 0,
                  est_markup: 0,
                  inverse_convert_amt_display: 0,
                  inverse_currency_amt_display: 0,
                  markup_price: 0,
                },
              ],
              new_commission: 0,
              filter_client_id: 0,
              filter_client_qty: 0,
              market_value_text: 0,
              vendor: d_vid,
              gtd_expire_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
              display_quantity: 0,
              allocates_input_fees: 0,
              allocates_input_set_fees: 0,
            })
          );
          let currentMarketId = localStorage.getItem("current_market_id");
          if (Number(currentMarketId) === 6) {
            console.log("default_security_set-IN-vendor-update-forex", default_security_set);
            dispatch(update_form_data({ vendor: 2 }));
            setShowVendorOption(false);
          }
        }

        set_confirm({
          account_name: null,
          amount: 0,
          security_name: null,
          isin: null,
          direction: null,
          type: "MARKET",
          quantity: 0,
          price: 0,
          fees: 0,
          net_consideration: 0,
          time: "day",
          currency: "",
        });

        setIsConfirmClose(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [isconfirmClose]);

  const closeConfirmAlert = () => {
    if (form.vendor === 7) {
      let timer_request_rfq = {
        bid_price: 0,
        ask_price: 0,
      };
      set_timer_request_rfq(timer_request_rfq);
    }
    set_order_confirm_dialog(false);
    set_is_submitclickStart(false);
    set_getquote_disabled_confirm(false);
    dispatch(updateresponsePlaceOrder());
    setIsConfirmClose(true);
    dispatch(clear_form_allocates_inputs());
    dispatch(update_form_data({ isConfirm: false }));
    dispatch(update_form_data({ isInverse: false }));
    setIsInverse(false);
    setDisplayCurrency(primaryCurrecnyValue);
    // set_order_confirm_dialog(false);
    // set_is_submitOrderFrom(false);
    // set_is_submitclickStart(false);
    // set_disabled_confirm(0);
    // set_confirm_btn_labl("Submit");
    if (form.vendor === 7) {
      setSeconds(0);
    }
  };
  const confirmClose = () => {
    if (form.vendor === 7) {
      let timer_request_rfq = {
        bid_price: 0,
        ask_price: 0,
      };
      set_timer_request_rfq(timer_request_rfq);
    }
    set_order_confirm_dialog(false);
    set_is_submitclickStart(false);
    set_getquote_disabled_confirm(false);
    dispatch(updateresponsePlaceOrder());
    setIsConfirmClose(true);
    dispatch(clear_form_allocates_inputs());
    dispatch(update_form_data({ isConfirm: false }));
    dispatch(update_form_data({ isInverse: false }));
    setIsInverse(false);
    setDisplayCurrency(primaryCurrecnyValue);
  };

  const rendorNullSelect = (clientIndex: any) => {
    if (rfqFormType === "bond") {
      dispatch(update_rfq_form_data({ filter_client_id: 0 }));
      dispatch(update_rfq_form_data({ filter_client_qty: 0 }));
      dispatch(
        update_form_allocates_inputs({ index: clientIndex, field: "client_id", value: null, mktid: localStorage.getItem("current_market_id") })
      );

      // dispatch(
      //   update_map_allocates_inputs({
      //     allocates_inputs: allocates_inputs,
      //     form_type: false,
      //     client_id: 0,
      //     updated_index: clientIndex,
      //   })
      // );
    } else {
      console.log("callhere -----null ", clientIndex);
      dispatch(update_form_data({ filter_client_id: 0 }));
      dispatch(update_form_data({ filter_client_qty: 0 }));
      dispatch(
        update_form_allocates_inputs({ index: clientIndex, field: "client_id", value: null, mktid: localStorage.getItem("current_market_id") })
      );
      // dispatch(update_form_allocates_inputs({ index: clientIndex, field: "qty", value: 0 }));
      dispatch(
        update_form_allocates_inputs({ index: clientIndex, field: "markup_type", value: "abs", mktid: localStorage.getItem("current_market_id") })
      );
      dispatch(
        update_form_allocates_inputs({ index: clientIndex, field: "markup_value", value: 0, mktid: localStorage.getItem("current_market_id") })
      );
      dispatch(update_form_allocates_inputs({ index: clientIndex, field: "percentage", value: 0, mktid: localStorage.getItem("current_market_id") }));
      dispatch(update_form_allocates_inputs({ index: clientIndex, field: "set_fees", value: 0, mktid: localStorage.getItem("current_market_id") }));
      dispatch(update_form_allocates_inputs({ index: clientIndex, field: "fees", value: 0, mktid: localStorage.getItem("current_market_id") }));
      // dispatch(
      //   update_map_allocates_inputs({
      //     allocates_inputs: allocates_inputs,
      //     form_type: false,
      //     client_id: 0,
      //     updated_index: clientIndex,
      //     qty: 0,
      //   })
      // );
      // dispatch(
      //   update_map_allocates_inputs({
      //     allocates_inputs: allocates_inputs,
      //     form_type: false,
      //     client_id: 0,
      //     updated_index: clientIndex,
      //     markup_type: "abs",
      //     markup_value: 0,
      //     qty: 0,
      //   })
      // );
    }
    dispatch(update_form_data({ allocate_portfolios: allocates_inputs }));
  };
  const [currenctIndexUpdate, setCurrenctIndexUpdate] = useState(0);
  const renderClientFees = (client_id: any, e: any, clientIndex: any, clientQty: any) => {
    // console.log("select value", e.value);
    set_disabled_confirm(1);
    set_is_call_get_fees(true);
    if (rfqFormType === "bond") {
      setCurrenctIndexUpdate(clientIndex);
      dispatch(update_rfq_form_data({ filter_client_id: e.value }));
      dispatch(update_rfq_form_data({ filter_client_qty: clientQty }));

      // dispatch(
      //   update_map_allocates_inputs({
      //     allocates_inputs: allocates_inputs,
      //     form_type: false,
      //     client_id: e.value,
      //     updated_index: clientIndex,
      //   })
      // );
      dispatch(
        update_form_allocates_inputs({ index: clientIndex, field: "client_id", value: e.value, mktid: localStorage.getItem("current_market_id") })
      );
    } else {
      setCurrenctIndexUpdate(clientIndex);
      // console.log("callhere ----- before value", allocates_inputs);
      dispatch(
        update_form_allocates_inputs({ index: clientIndex, field: "client_id", value: e.value, mktid: localStorage.getItem("current_market_id") })
      );
      dispatch(update_form_data({ filter_client_id: e.value }));
      dispatch(update_form_data({ filter_client_qty: clientQty }));

      // dispatch(
      //   update_map_allocates_inputs({
      //     allocates_inputs: allocates_inputs,
      //     form_type: true,
      //     client_id: e.value,
      //     updated_index: clientIndex,
      //     // qty: f_qty,
      //   })
      // );
    }
    // dispatch(update_form_data({ allocate_portfolios: allocates_inputs }));
  };
  useEffect(() => {
    dispatch(update_form_data({ allocate_portfolios: allocates_inputs }));

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [allocates_inputs]);

  useEffect(() => {
    (async () => {
      if (form.filter_client_id || rfq_form.filter_client_id) {
        if (rfqFormType === "bond") {
          dispatch(update_rfq_form_data({ order_executed_id: orderexecutedid }));
          let payload = rfq_form;
          //get-fees
          dispatch(getFees(payload));
        } else {
          let payload = form;
          //get-fees
          // For equals
          if (Number(form.vendor) === 7) {
            set_getquote_disabled_confirm(true);
          }
          if (is_call_get_fees) {
            dispatch(getFees(payload));
          }
        }
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [form.filter_client_id, rfq_form.filter_client_id, currenctIndexUpdate, changeQty, is_call_get_fees]);
  const [markuptype, setMarkuptype] = useState("");
  const [markuptypevalue, setMarkuptypevalue] = useState(0);
  useEffect(() => {
    console.log("markuptype::", markuptype);
    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [markuptype]);
  useEffect(() => {
    (async () => {
      console.log("last update::", responseGetFees);
      if (responseGetFees.status === 1) {
        if (Number(form.vendor) === 7) {
          set_getquote_disabled_confirm(false);
        }
        if (Object.keys(responseGetFees).length > 0) {
          set_disabled_confirm(0);
          if (rfqFormType === "bond") {
            // alert(4);
            dispatch(
              update_form_allocates_inputs({
                client_id: rfq_form.filter_client_id,
                field: "original_fees",
                value: responseGetFees.fees,
                mktid: localStorage.getItem("current_market_id"),
              })
            );
            dispatch(
              update_form_allocates_inputs({
                client_id: rfq_form.filter_client_id,
                field: "set_fees",
                value: responseGetFees.set_fees,
                mktid: localStorage.getItem("current_market_id"),
              })
            );
            dispatch(
              update_form_allocates_inputs({
                client_id: rfq_form.filter_client_id,
                field: "fees",
                value: responseGetFees.fees,
                mktid: localStorage.getItem("current_market_id"),
              })
            );
            // dispatch(

            //   update_map_allocates_inputs({
            //     allocates_inputs: allocates_inputs,
            //     form_type: false,
            //     client_id: rfq_form.filter_client_id,
            //     set_fees: responseGetFees.set_fees,
            //     fees: responseGetFees.fees,
            //   })
            // );
          } else {
            if (form.filter_client_id === responseGetFees.portfolio_id) {
              dispatch(
                update_form_allocates_inputs({
                  client_id: responseGetFees.portfolio_id,
                  field: "original_fees",
                  value: responseGetFees.fees,
                  mktid: localStorage.getItem("current_market_id"),
                })
              );
              dispatch(
                update_form_allocates_inputs({
                  client_id: responseGetFees.portfolio_id,
                  field: "set_fees",
                  value: responseGetFees.set_fees,
                  mktid: localStorage.getItem("current_market_id"),
                })
              );
              dispatch(
                update_form_allocates_inputs({
                  client_id: responseGetFees.portfolio_id,
                  field: "fees",
                  value: responseGetFees.fees,
                  mktid: localStorage.getItem("current_market_id"),
                })
              );
              allocates_inputs.forEach((item) => {
                if (Number(item.markup_value) === 0) {
                  dispatch(
                    update_form_allocates_inputs({
                      client_id: responseGetFees.portfolio_id,
                      field: "markup_type",
                      value: responseGetFees.mark_up_data.type,
                      mktid: localStorage.getItem("current_market_id"),
                    })
                  );
                }
              });

              dispatch(
                update_form_allocates_inputs({
                  client_id: responseGetFees.portfolio_id,
                  field: "markup_value",
                  value: responseGetFees.mark_up_data.value,
                  mktid: localStorage.getItem("current_market_id"),
                })
              );
              // if (Number(responseGetFees.mark_up_data.value) !== 0) {
              // }
              set_is_change_markup_data(true);
              // dispatch(
              //   update_map_allocates_inputs({
              //     allocates_inputs: allocates_inputs,
              //     form_type: true,
              //     // client_id: form.filter_client_id,
              //     client_id: responseGetFees.portfolio_id,
              //     set_fees: responseGetFees.set_fees,
              //     fees: responseGetFees.fees,
              //     markup_type: responseGetFees.mark_up_data.type,
              //     markup_value: responseGetFees.mark_up_data.value,
              //   })
              // );
            }
          }
          allocates_inputs.map(function (item) {
            return item;
          });
          setMarkuptype(responseGetFees.mark_up_data.type);
          setMarkuptypevalue(responseGetFees.mark_up_data.value);
        }
      } else {
        set_disabled_confirm(1);
        toast.error(responseGetFees.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [responseGetFees]);

  useEffect(() => {
    (async () => {
      if (is_confirm_set) {
        updateAllocation(1, 0);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [is_confirm_set]);
  const updateAllocation = (is_auto: any, quantity: any) => {};
  const add_allocate = (index: any) => {
    let newopt = {
      client_id: null,
      qty: "0",
      percentage: "",
      fees: 0,
      set_fees: 0,
      markup_fees: 0,
      markup_type: "abs",
      markup_value: 0,
      est_markup: 0,
      inverse_convert_amt_display: 0,
      inverse_currency_amt_display: 0,
      amount: 0,
      markup_price: 0,
    };
    dispatch(add_form_allocates_inputs({ data: [newopt], mktid: localStorage.getItem("current_market_id") }));
  };
  const remove_allocate = (index: any, client_id_removed: any) => {
    const indexRemoved = allocates_inputs.findIndex(({ client_id }) => client_id === client_id_removed);
    if (indexRemoved !== -1) {
      dispatch(remove_form_allocates_inputs({ index: index, mktid: localStorage.getItem("current_market_id") }));
    }
    set_disabled_confirm(0);
    // if (rfqFormType === "bond") {
    //   let payload = rfq_form;
    //   //get-fees
    //   dispatch(getFees(payload));
    // } else {
    //   let payload = form;
    //   //get-fees
    //   dispatch(getFees(payload));
    // }
  };
  const onSubmit = (e: any) => {
    e.preventDefault();
    setSubmitted(true);
    set_disabled_confirm(1);
    let validate = 1;
    let validate_message = "";
    const display_quantity = Number(form.display_quantity);
    const quantity = Number(form.quantity);

    if (display_quantity > quantity) {
      validate = 0;
      validate_message = "Display quantity must be less than quantity or zero";
    }
    if (validate) {
      set_confirm_btn_labl("Submitting...");
      set_disabled_confirm(1);
      dispatch(
        update_form_data({
          bid: bid_value,
          ask: ask_value,
          last_price: last_price_value,
          fund_amount: Number(String(form.fund_amount).replace(/,/g, "")),
        })
      );
      // dispatch(update_form_data({ allocate_portfolios: allocates_inputs }));
      set_is_submit_place_order(true);
    } else {
      toast.error(validate_message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    if (form.isConfirm) {
      console.log("isConfirm", form.isConfirm);
      console.log("isConfirm form", form);
      // console.log("isConfirm currentMarketId", Number(currentMarketId));
      console.log("isConfirm ask_value", Number(ask_value));
      console.log("isConfirm isInverse", form.isInverse);
      console.log("isConfirm direction", form.direction.toLowerCase());
      console.log("isConfirm direction", form.order_type.toLowerCase());
      if (form.vendor === 7) {
        // setSeconds(0);
      }
      dispatch(placeOrder(form));
    }
    // eslint-disable-next-line
  }, [form.isConfirm]);
  const [is_submit_place_order, set_is_submit_place_order] = useState(false);
  useEffect(() => {
    if (is_submit_place_order) {
      let validationType = "fx_eq";
      let cMid: any = localStorage.getItem("current_market_id");
      if (Number(cMid) === 8) {
        validationType = "fund";
      }
      console.log("clientVendors::", clientVendors);
      let isValidation = HandleFormValidation(validationType, form);
      if (isValidation) {
        var user_id = localStorage.getItem("login-id");
        let isConfirm = false;
        let currentMarketId = localStorage.getItem("current_market_id");
        if (loggedType === "internal" && Number(form.is_calculate) === 0 && Number(currentMarketId) !== 8) {
          if (form.isInverse === true) {
            if (
              form.direction.toLowerCase() === "buy" &&
              form.order_type.toLowerCase() === "market" &&
              (Number(ask_value) === 0 || isNaN(Number(ask_value)))
            ) {
              isConfirm = true;
            }

            if (
              form.direction.toLowerCase() === "sell" &&
              form.order_type.toLowerCase() === "market" &&
              (Number(bid_value) === 0 || isNaN(Number(bid_value))) &&
              Number(currentMarketId) === 6
            ) {
              // isConfirm = true;
            }
          } else {
            if (
              form.direction.toLowerCase() === "buy" &&
              form.order_type.toLowerCase() === "market" &&
              (Number(ask_value) === 0 || isNaN(Number(ask_value)))
            ) {
              isConfirm = true;
            }

            if (
              form.direction.toLowerCase() === "sell" &&
              form.order_type.toLowerCase() === "market" &&
              (Number(bid_value) === 0 || isNaN(Number(bid_value))) &&
              Number(currentMarketId) === 6
            ) {
              // isConfirm = true;
            }
          }
        }

        if (isConfirm) {
          confirmAlert({
            title: "Execution Warning",
            message: "We cannot validate client balance as live price is not available for this security. Do you still want to execute this order?",
            buttons: [
              {
                label: "Yes",
                className: "custom-btn ok-btn",
                onClick: () => dispatch(update_form_data({ isConfirm: true })),
              },
              {
                label: "No",
                className: "custom-btn cancel-btn",
                onClick: () => closeConfirmAlert(),
              },
            ],
            closeOnClickOutside: false,
          });
        } else {
          if (form.vendor === 7) {
            // setSeconds(0);
          }
          dispatch(placeOrder(form));
        }
      } else {
        set_disabled_confirm(0);
        set_confirm_btn_labl("Submit");
      }
      set_is_submit_place_order(false);
    }
    // eslint-disable-next-line
  }, [is_submit_place_order]);
  //START:: final submit for order
  const [is_submitOrderFrom, set_is_submitOrderFrom] = useState(false);
  useEffect(() => {
    (async () => {
      if (is_submitOrderFrom) {
        hiddenclickFire(true);
        set_is_submitOrderFrom(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [is_submitOrderFrom]);
  useEffect(() => {
    if (place_order_order === true) {
      set_disabled_confirm(1);
    } else if (place_order_order === false) {
      set_disabled_confirm(0);
    }
  }, [place_order_order]);

  const hiddenclickFire = (e: any) => {
    inputButtonElement.click();
  };

  const submitOrderFrom = (e: any) => {
    set_is_submitclickStart(true);
    set_is_submitOrderFrom(true);
    if (isRFQForm) {
      dispatch(update_rfq_form_data({ is_calculate: 0 }));
    } else {
      dispatch(update_form_data({ is_calculate: 0 }));
    }
  };
  const getFXQuote = (e: any) => {
    set_getquote_disabled_confirm(true);
    dispatch(getFXQuoteRequest(form));
  };

  useEffect(() => {
    (async () => {
      if (isclearForm) {
        let d_vid = 0;
        const current_market_id = localStorage.getItem("current_market_id");
        if (clientVendors) {
          const foundObject: any = clientVendors.find((item: any) => Number(item.market_type_id) === Number(current_market_id));
          if (foundObject !== undefined) {
            if (Number(current_market_id) !== 5) {
              d_vid = foundObject.vendor_id;
            } else {
              d_vid = foundObject.vendor_id;
            }
          }
        }
        if (loggedType === "internal" && d_vid === 0 && Number(current_market_id) === 5) {
          d_vid = 8;
        }
        if (loggedType === "internal" && d_vid === 0 && Number(current_market_id) !== 5) {
          d_vid = 2;
        }
        dispatch(
          update_form_data({
            isConfirm: false,
            isInverse: false,
            ltp_price_data: {},
            fx_quote_data: {},
            order_type: "market",
            fund_order_type: "share",
            fund_amount: 0,
            day: "day",
            quantity: "0",
            price: 0.0,
            stop_price: 0.0,
            portfolio_id: 0,
            bid: 0,
            ask: 0,
            rate: 0,
            last_price: 0,
            direction: "",
            security_id: Number(localStorage.getItem("current_security_id")),
            is_calculate: 1,
            allocate_portfolios: [
              {
                client_id: null,
                qty: "0",
                percentage: "",
                fees: 0,
                set_fees: 0,
                markup_fees: 0,
                amount: 0,
                markup_type: "abs",
                markup_value: 0,
                est_markup: 0,
                inverse_convert_amt_display: 0,
                inverse_currency_amt_display: 0,
                markup_price: 0,
              },
            ],
            new_commission: 0,
            filter_client_id: 0,
            filter_client_qty: 0,
            market_value_text: 0,
            vendor: d_vid,
            gtd_expire_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            display_quantity: 0,
            allocates_input_fees: 0,
            allocates_input_set_fees: 0,
          })
        );
        set_net_consideration(0);
        set_fees(0);
        set_order_confirm_dialog(false);
        setIsClearForm(false);
        if (Number(current_market_id) === 6) {
          console.log("default_security_set-IN-vendor-update-forex", default_security_set);
          dispatch(update_form_data({ vendor: d_vid }));
          setShowVendorOption(false);
        }
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [isclearForm]);
  const clearForm = () => {
    setIsClearForm(true);
  };
  //END:: final submit for order
  //START:: place order api manage response
  useEffect(() => {
    (async () => {
      if (Object.keys(responsePlaceOrder).length > 0) {
        set_is_submitOrderFrom(false);
        set_disabled_confirm(0);
        set_confirm_btn_labl("Submit");
        if (responsePlaceOrder.status === 1) {
          if (form.is_calculate === 0) {
            if (responsePlaceOrder.api_error === 1) {
              toast.error(responsePlaceOrder.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              setIsClearForm(true);
              var user_id = localStorage.getItem("login-id");
              let show_toaster = responsePlaceOrder.trade_record.show_toaster;
              if (show_toaster) {
                toast.success(
                  <>
                    <p className="font-bold">{responsePlaceOrder?.message_title}</p>
                    <p className="text-xs">{responsePlaceOrder?.message_description}</p>
                  </>,
                  {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  }
                );
              }

              dispatch(clear_form_allocates_inputs());
              dispatch(is_update_transaction_data(true));
              set_order_confirm_dialog(false);
              setDisplayCurrency(primaryCurrecnyValue);
              setIsInverse(false);
            }
          } else {
            dispatch(is_update_transaction_data(false));
            set_is_confirm_set(true);
            //set confirm data set_confirm
            set_confirm({
              ...confirm,
              account_name: responsePlaceOrder.data.account,
              amount: responsePlaceOrder.data.amount,
              currency: responsePlaceOrder.data.currency,
              security_name: responsePlaceOrder.data.security,
              isin: responsePlaceOrder.data.isin_code,
              direction: responsePlaceOrder.data.direction,
              quantity: responsePlaceOrder.data.quantity,
              price: responsePlaceOrder.data.price,
              type: responsePlaceOrder.data.type,
              time: responsePlaceOrder.data.time,
              fees: responsePlaceOrder.data.fees,
              net_consideration: responsePlaceOrder.data.net_consideration,
            });
            dispatch(
              update_form_data({
                market_value_text: responsePlaceOrder.data.net_consideration,
              })
            );
            set_disabled_confirm(0);
            set_order_confirm_dialog(true);
            // set_disabled_confirm(0);
            set_is_commission(responsePlaceOrder.data.is_commission);
            set_is_fees_display(responsePlaceOrder.data.is_fees);
            set_is_global_fees_display(responsePlaceOrder.data.is_global_fees_display);
            // dispatch(
            //   update_map_allocates_inputs({
            //     allocates_inputs: allocates_inputs,
            //     qty: rfq_form.quantity,
            //     updated_index: 0,
            //     form_type: true,
            //     set_fees: 0,
            //     fees: 0,
            //   })
            // );
            dispatch(
              update_form_allocates_inputs({ index: 0, field: "qty", value: form.quantity, mktid: localStorage.getItem("current_market_id") })
            );
            dispatch(update_form_allocates_inputs({ index: 0, field: "percentage", value: 100, mktid: localStorage.getItem("current_market_id") }));
          }
        } else {
          toast.error(responsePlaceOrder.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [responsePlaceOrder]);
  //END:: place order api manage response

  useEffect(() => {
    (async () => {
      let type = form.fund_order_type;
      set_quantityFieldState(false);
      set_amountFieldState(false);
      dispatch(update_form_data({ fund_amount: 0 }));
      set_disabled(true);
      if (type === "share") {
        set_qty_label("Shares");
        set_quantityFieldState(true);
      } else if (type === "amount") {
        set_qty_label("Market Value");
        set_amountFieldState(true);
      }
      // this.loading = false;
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [form.fund_order_type]);
  const onChangeRequestType = (e: any) => {
    dispatch(update_form_data({ fund_order_type: e.value }));
  };
  const [showInformationWindowFlag, setShowInformationWindowFlag] = useState(false);

  const ref: any = useRef(null);

  useOnClickOutside(ref, () => {
    setShowInformationWindowFlag(false);
  });
  const handleSecurityDetailWindow = (isDisplay: any) => {
    console.log("handleSecurityDetailWindow ---[ok]", isDisplay);
    setShowInformationWindowFlag(isDisplay);
  };
  // START:: RFQ Request
  const onRFQSubmit = (e: any) => {
    e.preventDefault();
    let validate = 1;
    set_disabled_confirm(1);
    if (rfq_form.step === 2) {
      set_isRfqBtn(true);
    } else {
      set_confirm_btn_labl("Submitting...");
      set_disabled_confirm(0);
    }
    // console.log("RFQ:: --------allocates_inputs ---[ok]", allocates_inputs);
    // dispatch(update_rfq_form_data({ allocate_portfolios: allocates_inputs }));

    set_is_submit_fxspot_order(true);
  };
  const [is_submit_fxspot_order, set_is_submit_fxspot_order] = useState(false);
  useEffect(() => {
    if (is_submit_fxspot_order) {
      let isValidation = HandleFormValidation("bond", rfq_form);
      if (isValidation) {
        set_disabled_confirm(1);
        set_idle_first_rfq_timer(11);
        set_is_tradeweb_received(false);
        dispatch(placeFxspotOrder(rfq_form));
        set_disabled_confirm(0);
      } else {
        set_disabled_confirm(1);
        set_confirm_btn_labl("Submit");
      }
      set_is_submit_fxspot_order(false);
    }

    // eslint-disable-next-line
  }, [is_submit_fxspot_order]);
  useEffect(() => {
    (() => {
      if (Object.keys(responseplaceFxspotOrder).length > 0) {
        set_is_submitOrderFrom(false);
        set_disabled_confirm(0);
        // console.log("RFQ:: responseplaceFxspotOrder ---[ok]", responseplaceFxspotOrder);
        // console.log("RFQ:: rfq_form ---[ok]", rfq_form.step);
        if (rfq_form.step === 2) {
          if (rfq_form.direction === "BUY") {
            set_lift_btn_label("Lift");
          }
          if (rfq_form.direction === "SELL") {
            set_hit_btn_label("Hit");
          }
        } else {
          set_disabled_confirm(0);
          set_confirm_btn_labl("Submit");
        }
        if (responseplaceFxspotOrder.status === 1) {
          if (responseplaceFxspotOrder.returnType === "hit_lift") {
            if (typeof responseplaceFxspotOrder.message_title !== "undefined") {
              //setIsClearForm(true);
              var user_id = localStorage.getItem("login-id");
              let show_toaster = responseplaceFxspotOrder.trade_record.show_toaster;
              if (show_toaster) {
                toast.success(
                  <>
                    <p className="font-bold">{responseplaceFxspotOrder?.message_title}</p>
                    <p className="text-xs">{responseplaceFxspotOrder?.message_description}</p>
                  </>,
                  {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  }
                );
              }
              if (
                responseplaceFxspotOrder.trade_record.id !== 0 &&
                responseplaceFxspotOrder.trade_record.id !== "0" &&
                typeof responseplaceFxspotOrder.trade_record.id !== "undefined"
              ) {
                dispatch(is_update_transaction_data(true));
              }
              dispatch(clear_form_allocates_inputs());
              set_is_clear_rfq_form(true);
              // resetRFQForm();
              set_order_confirm_dialog(false);
              set_disabled_confirm(1);
            } else {
              // update principal amount
              set_principal_amount(responseplaceFxspotOrder.data.princiPalAmount);
              dispatch(update_form_allocates_inputs({ index: 0, field: "percentage", value: 100, mktid: localStorage.getItem("current_market_id") }));
              dispatch(
                update_form_allocates_inputs({
                  index: 0,
                  field: "qty",
                  value: responseplaceFxspotOrder.data.quantity,
                  mktid: localStorage.getItem("current_market_id"),
                })
              );
              // dispatch(update_form_allocates_inputs({ index: 0, field: "set_fees", value: responseGetFees.set_fees }));
              // /dispatch(update_form_allocates_inputs({ index: 0, field: "fees", value: responseGetFees.fees }));
              // dispatch(
              //   update_map_allocates_inputs({
              //     allocates_inputs: allocates_inputs,
              //     percentage: 100,
              //     updated_index: 0,
              //     form_type: false,
              //     set_fees: responseGetFees.set_fees,
              //     fees: responseGetFees.fees,
              //   })
              // );
              set_order_confirm_dialog(true);
              // set_disabled_confirm(0);
              set_disabled_confirm(1);
              set_confirm({
                ...confirm,
                account_name: responseplaceFxspotOrder.data.account,
                amount: responseplaceFxspotOrder.data.amount,
                currency: responseplaceFxspotOrder.data.currency,
                security_name: responseplaceFxspotOrder.data.security,
                isin: responseplaceFxspotOrder.data.isin_code,
                direction: responseplaceFxspotOrder.data.direction,
                quantity: responseplaceFxspotOrder.data.quantity,
                price: responseplaceFxspotOrder.data.price,
                type: responseplaceFxspotOrder.data.type,
                time: responseplaceFxspotOrder.data.time,
                fees: responseplaceFxspotOrder.data.fees,
                net_consideration: responseplaceFxspotOrder.data.net_consideration,
              });
              set_is_confirm_set(true);
              set_disabled_confirm(1);
              set_order_confirm_dialog(true);
              set_is_commission(responseplaceFxspotOrder.data.is_commission);
              set_is_fees_display(responseplaceFxspotOrder.data.is_fees);
              set_is_global_fees_display(responseplaceFxspotOrder.data.is_global_fees_display);
            }
          } else {
            if (typeof responseplaceFxspotOrder.message_title !== "undefined") {
              var user_id = localStorage.getItem("login-id");
              let show_toaster = responseplaceFxspotOrder.trade_record.show_toaster;
              if (show_toaster) {
                toast.success(
                  <>
                    <p className="font-bold">{responseplaceFxspotOrder?.message_title}</p>
                    <p className="text-xs">{responseplaceFxspotOrder?.message_description}</p>
                  </>,
                  {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  }
                );
              }
              if (
                responseplaceFxspotOrder.trade_record.id !== 0 &&
                responseplaceFxspotOrder.trade_record.id !== "0" &&
                typeof responseplaceFxspotOrder.trade_record.id !== "undefined"
              ) {
                dispatch(is_update_transaction_data(true));
              }

              set_is_clear_rfq_form(true);
              set_first_timer_dialog(false);
              set_is_order_placed(false);
              // resetRFQForm();
              set_order_confirm_dialog(false);
              set_disabled_confirm(1);
              set_confirm({
                account_name: null,
                amount: 0,
                security_name: null,
                isin: null,
                direction: null,
                type: "MARKET",
                quantity: 0,
                price: 0,
                fees: 0,
                net_consideration: 0,
                time: "day",
                currency: "",
              });
              first_timer_dialog_cancel(1);
            } else {
              if (responseplaceFxspotOrder.trade_way === "rfq") {
                dispatch(
                  update_rfq_form_data({
                    step: 2,
                    trade_way: "rfq",
                    security_id: Number(localStorage.getItem("current_security_id")),
                  })
                );
                //open timer 1 dialog
                set_first_timer_dialog(true);
                set_rfq_current_step(2);
                requestRFQ();
              } else {
                dispatch(update_rfq_form_data({ trade_way: "hit_lift" }));
                if (rfq_form.rfq_request_id) {
                  if (responseplaceFxspotOrder.api_error === 1) {
                    toast.error(responseplaceFxspotOrder.message, {
                      position: "top-right",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                    resetRFQForm();
                  } else {
                    dispatch(
                      update_rfq_form_data({
                        step: 2,
                        order_executed_id: responseplaceFxspotOrder.order_executed_id,
                      })
                    );
                    set_rfq_current_step(2);
                    set_orderexecutedid(responseplaceFxspotOrder.order_executed_id);
                    set_rfq_global_response(responseplaceFxspotOrder);
                    startstreamForResponse();
                  }
                } else {
                  set_confirm({
                    ...confirm,
                    account_name: responseplaceFxspotOrder.data.account,
                    amount: responseplaceFxspotOrder.data.amount,
                    currency: responseplaceFxspotOrder.data.currency,
                    security_name: responseplaceFxspotOrder.data.security,
                    isin: responseplaceFxspotOrder.data.isin_code,
                    direction: responseplaceFxspotOrder.data.direction,
                    quantity: responseplaceFxspotOrder.data.quantity,
                    price: responseplaceFxspotOrder.data.price,
                    type: responseplaceFxspotOrder.data.type,
                    time: responseplaceFxspotOrder.data.time,
                    fees: responseplaceFxspotOrder.data.fees,
                    net_consideration: responseplaceFxspotOrder.data.net_consideration,
                  });
                  set_is_confirm_set(true);
                  set_disabled_confirm(0);
                  set_order_confirm_dialog(true);
                  set_is_commission(responseplaceFxspotOrder.data.is_commission);
                  set_is_fees_display(responseplaceFxspotOrder.data.is_fees);
                  set_is_global_fees_display(responseplaceFxspotOrder.data.is_global_fees_display);
                }
              }
            }
          }
        } else {
          if (allocate_portfolio.length > 1) {
            //set_is_order_placed(true);
            // this.is_order_placed = true;
          } else {
            if (orderexecutedid) {
              set_first_timer_dialog(false);
            }
            set_disableRFQForm(false);
          }
          // alert(responseplaceFxspotOrder.message);
          toast.error(responseplaceFxspotOrder.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [responseplaceFxspotOrder]);
  // const [update_fx_rfq_timer, set_update_fx_rfq_timer] = useState<any>("00:00");
  // const [fxTimeLeft, setFXTimeLeft] = useState(0);
  // useEffect(() => {
  //   // exit early when we reach 0
  //   console.log("FX RFQ:: timer::call return--In", current_step + "==" + is_order_placed + "==fxTimeLeft=" + fxTimeLeft);
  //   if (!fxTimeLeft) {
  //     set_update_fx_rfq_timer("00:00");
  //   }
  //   // // save intervalId to clear the interval when the
  //   // // component re-renders
  //   const intervalId = setInterval(() => {
  //     setFXTimeLeft(fxTimeLeft - 1);
  //     // console.log("timer:: timeLeft", timeLeft + "==" + current_step);

  //     if (Number(fxTimeLeft) === 1) {
  //       // set_current_step(current_step + 1);
  //     }
  //   }, 1000);

  //   // clear interval on re-render to avoid memory leaks
  //   return () => clearInterval(intervalId);
  //   // add timeLeft as a dependency to re-rerun the effect
  //   // when we update it
  // }, [update_fx_rfq_timer]);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    let timerId: any;
    if (isActive && seconds > 0) {
      timerId = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0 && is_submitclickStart === false) {
      set_getquote_disabled_confirm(false);
      set_hide_quote_btn(false);
      set_timer_request_rfq({
        ...timer_request_rfq,
        bid_price: 0,
        ask_price: 0,
      });
      // set_timer_request_rfq({
      //   ...timer_request_rfq,
      //   ask_price: 0,
      // });
    }
    return () => clearTimeout(timerId);
  }, [isActive, seconds]);
  useEffect(() => {
    let timerId: any;

    if (seconds === 0 && is_submitclickStart === false) {
      set_getquote_disabled_confirm(false);
      set_hide_quote_btn(false);
      set_timer_request_rfq({
        ...timer_request_rfq,
        bid_price: 0,
        ask_price: 0,
      });
      // set_timer_request_rfq({
      //   ...timer_request_rfq,

      // });
    }
    return () => clearTimeout(timerId);
  }, [is_submitclickStart]);
  useEffect(() => {
    if (fx_rfq_quote_data) {
      if (String(fx_rfq_quote_data.key) !== "") {
        console.log("fx_rfq_quote_data :: Stream", fx_rfq_quote_data);
        if (String(fx_rfq_quote_data.key) === "FX_QUOTE_RESPONSE") {
          // hide get quote button
          set_getquote_disabled_confirm(false);
          set_hide_quote_btn(true);
          // set_update_fx_rfq_timer(10);
          setSeconds(10);
          setIsActive(true);
          if (form.direction.toLowerCase() === "sell") {
            dispatch(update_form_data({ bid: fx_rfq_quote_data.value.message.rate ?? 0 }));
            set_timer_request_rfq({
              ...timer_request_rfq,
              bid_price: fx_rfq_quote_data.value.message.rate,
            });
          }
          if (form.direction.toLowerCase() === "buy") {
            dispatch(update_form_data({ ask: fx_rfq_quote_data.value.message.rate ?? 0 }));
            set_timer_request_rfq({
              ...timer_request_rfq,
              ask_price: fx_rfq_quote_data.value.message.rate,
            });
          }
        } else {
          console.log("fx_rfq_quote_data :: Stream", fx_rfq_quote_data);
          set_getquote_disabled_confirm(false);
          // let emsg = fx_rfq_quote_data.value.message??'Please try again in a few minutes.';
          set_hide_quote_btn(false);
          toast.error("Quote not found.", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [fx_rfq_quote_data]);

  const [hide_quote_btn, set_hide_quote_btn] = useState(true);

  useEffect(() => {
    if (responseFXQuoteOrder) {
      console.log("responseFXQuoteOrder", responseFXQuoteOrder);
      if (responseFXQuoteOrder.request_id) {
        // hide get quote button
        // set_getquote_disabled_confirm(false);
        // set_hide_quote_btn(true);
      }
    }

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [responseFXQuoteOrder]);
  const [is_call_request_rfq, set_is_call_request_rfq] = useState(false);
  useEffect(() => {
    (async () => {
      if (is_call_request_rfq === true) {
        dispatch(
          update_rfq_form_data({
            step: 2,
            security_id: Number(localStorage.getItem("current_security_id")),
          })
        );
        set_rfq_current_step(2);
        set_rfqLabel("Requesting...");
        setTimeout(() => {
          // console.log("RFQ:: call requestRFQ paylod", rfq_form);
          let payload = rfq_form;
          dispatch(requestRfq(payload));
        }, 500);
        set_is_call_request_rfq(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [is_call_request_rfq]);
  const requestRFQ = () => {
    set_is_call_request_rfq(true);
  };
  useEffect(() => {
    (async () => {
      if (rfqrequestid) {
        dispatch(
          update_rfq_form_data({
            rfq_request_id: rfqrequestid,
            order_executed_id: orderexecutedid,
          })
        );
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [rfqrequestid]);
  useEffect(() => {
    (async () => {
      if (Object.keys(responseCancelRFQ).length > 0) {
        // console.log("RFQ:: cancel respose", responseCancelRFQ);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [responseCancelRFQ]);
  const [idle_first_rfq_timer, set_idle_first_rfq_timer] = useState<any>(0);
  useEffect(() => {
    (async () => {
      if (idle_first_rfq_timer !== 0) {
        setTimeout(() => {
          set_idle_first_rfq_timer(idle_first_rfq_timer - 1);
        }, 1000);
      } else {
        console.log("idle_first_rfq_timer IN ZERO");
        set_idle_first_rfq_timer(0);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [idle_first_rfq_timer]);
  useEffect(() => {
    (async () => {
      if (Object.keys(responserequestRfq).length > 0) {
        if (responserequestRfq.status === 1) {
          set_rfqButton(false);
          if (is_zero_link_click) {
            set_disableRFQBtn(false);
          }
          set_is_zero_link_click(false);
          set_isRfqBtn(false);
          let request_id = responserequestRfq.data.request_id;
          // console.log("RFQ:: request_id ---[ok]", request_id);
          set_rfqrequestid(request_id);
          set_timer(60);
          let channelName = responserequestRfq.data.channel ? responserequestRfq.data.channel : "fxspot-quotes";
          set_rfq_channel_name(channelName);
          if (!is_third_link_open) {
            // set_idle_first_rfq_timer(10);
          }
          if (!zero_price_link) {
            setTimeout(() => {
              set_zero_price_link(true);
            }, 8000);
          }
        } else {
          set_cancelButton(false);
          set_sellButton(false);
          set_buyButton(false);
          set_rfqButton(true);
          setTimeLeft(0);
          toast.error(responserequestRfq.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [responserequestRfq]);

  const sleep = (ms: any) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  //START IB stream
  const channel = localStorage.getItem("stream-channel");
  const rfs_stream_channel = "rfs_stream_channel_" + localStorage.getItem("login-id");
  // const rfs_stream_channel = "rfs_stream_channel_" + localStorage.getItem("current_socket_id");
  // const rfs_stream_channel = "rfs_stream_channel";
  const ltpStreamChannel = "equity_forex_ltp_stream_" + localStorage.getItem("login-id");
  const bondChartDataChannel = "bond_chart_bidask_data_channel_" + localStorage.getItem("login-id");
  const equityChartDataChannel = "equity_chart_bidask_data_channel_" + localStorage.getItem("login-id");
  const [is_first_call, set_is_first_call] = useState(0);

  const rfs_stream_channel_data: any = useAppSelector((state) => state.orderSection.rfs_stream_channel_data);
  useEffect(() => {
    if (rfs_stream_channel_data) {
      let currentReqvid = localStorage.getItem("current_reqvid_id");
      let payload = {};
      console.log("RFS_best_stream_channel:: ------------------------ Order Execution", rfs_stream_channel_data);
      let last_quote_update = {};
      if (rfs_stream_channel_data.typ === "ask") {
        set_bond_quote_ask_size(rfs_stream_channel_data.sz);
        set_bond_quote_ask_vendor(rfs_stream_channel_data.vid);
        let payload = {
          // ask_price: 90,
          ask_price: parseFloat(rfs_stream_channel_data.px.toString().slice(0, rfs_stream_channel_data.px.toString().indexOf(".") + 4)),
        };
        dispatch(update_ask_prices(payload));
        // locak ask data for order requets
        last_quote_update = {
          securityId: rfs_stream_channel_data.sec,
          dbSecurityId: rfs_stream_channel_data.dbsecid,

          bidQuoteId: rfq_form.rfs_lock_quote.bidQuoteId,
          bidVendorId: rfq_form.rfs_lock_quote.bidVendorId,
          bidPrice: rfq_form.rfs_lock_quote.bidPrice,
          bidSize: rfq_form.rfs_lock_quote.bidSize,
          bidTime: rfq_form.rfs_lock_quote.bidTime,
          bidMsz: rfq_form.rfs_lock_quote.bidMsz,
          bidReqvid: rfq_form.rfs_lock_quote.bidReqvid,

          askQuoteId: rfs_stream_channel_data.qid,
          askVendorId: rfs_stream_channel_data.vid,
          askPrice: rfs_stream_channel_data.px,
          askSize: rfs_stream_channel_data.sz,
          askTime: rfs_stream_channel_data.tm,
          askMsz: rfs_stream_channel_data.msz,
          askReqvid: rfs_stream_channel_data.reqvid,
        };
      }
      if (rfs_stream_channel_data.typ === "bid") {
        set_bond_quote_bid_size(rfs_stream_channel_data.sz);
        set_bond_quote_bid_vendor(rfs_stream_channel_data.vid);
        let payload = {
          // bid_price: 89,
          bid_price: parseFloat(rfs_stream_channel_data.px.toString().slice(0, rfs_stream_channel_data.px.toString().indexOf(".") + 4)),
        };
        dispatch(update_bid_prices(payload));
        //
        last_quote_update = {
          securityId: rfs_stream_channel_data.sec,
          dbSecurityId: rfs_stream_channel_data.dbsecid,

          bidQuoteId: rfs_stream_channel_data.qid,
          bidVendorId: rfs_stream_channel_data.vid,
          bidPrice: rfs_stream_channel_data.px,
          bidSize: rfs_stream_channel_data.sz,
          bidTime: rfs_stream_channel_data.tm,
          bidMsz: rfs_stream_channel_data.msz,
          bidReqvid: rfs_stream_channel_data.reqvid,

          askQuoteId: rfq_form.rfs_lock_quote.askQuoteId,
          askVendorId: rfq_form.rfs_lock_quote.askVendorId,
          askPrice: rfq_form.rfs_lock_quote.askPrice,
          askSize: rfq_form.rfs_lock_quote.askSize,
          askTime: rfq_form.rfs_lock_quote.askTime,
          askMsz: rfq_form.rfs_lock_quote.askMsz,
          askReqvid: rfq_form.rfs_lock_quote.askReqvid,
        };
      }
      // end bid condition
      // update rfs locak quote data
      dispatch(update_rfq_form_data({ rfs_lock_quote: last_quote_update }));
      dispatch(set_is_quote_price(true));
    }

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [rfs_stream_channel_data]);
  const [is_zero_link_click, set_is_zero_link_click] = useState(false);
  const [is_third_link_open, set_is_third_link_open] = useState(false);
  const [is_zero_link_click_loading, set_is_zero_link_click_loading] = useState("Click here");
  const handleNoPriceClickEvent = (RequestedDirection: any, quantity: any, limitprice: any) => {
    //prev send cancel event
    set_disableRFQBtn(false);
    set_is_zero_link_click(true);
    set_is_zero_link_click_loading("Fetching...");
    cancelRFQRequest(1);
    set_isRfqBtn(true);
    set_idle_first_rfq_timer(10);

    // send new rfq request
    setTimeout(() => {
      dispatch(update_rfq_form_data({ is_zero_price: true, direction: RequestedDirection, quantity: quantity, limit_price: limitprice }));
      requestRFQ();
    }, 1000);

    setTimeout(() => {
      // set_is_zero_link_click(false);
      set_is_zero_link_click_loading("Click here");
      if (is_tradeweb_received === false) {
        set_is_third_link_open(true);
      }

      //
      // toast.error("Quote not found please try again", {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    }, 10000);
  };
  const tradeweb_quotes_stream_channel_data: any = useAppSelector((state) => state.orderSection.tradeweb_quotes_stream_channel_data);
  useEffect(() => {
    console.log("RFQ:: New data ++++---[ok] In", tradeweb_quotes_stream_channel_data);
    if (tradeweb_quotes_stream_channel_data.typ && Number(rfq_form.rfq_request_id) !== 0 && is_zero_link_click === false) {
      set_zero_price_link(false);
      set_is_third_link_open(false);
      set_idle_first_rfq_timer(0);
      let streamcount = 0;
      const current_market_id = localStorage.getItem("current_market_id");
      if (Number(current_market_id) === 5 && Number(rfq_form.rfq_request_id) === Number(tradeweb_quotes_stream_channel_data.rid)) {
        //disable warnings for no price

        if (!is_order_placed) {
          console.log("RFQ:: New data ++++---[ok] ---- locak quote In", tradeweb_quotes_stream_channel_data);
          dispatch(update_rfq_form_data({ lock_quote: tradeweb_quotes_stream_channel_data }));
        }

        set_is_tradeweb_received(true);
        set_is_rfq_direction(tradeweb_quotes_stream_channel_data.direction);
        // we get settings data and can do something with it
        if (is_channel_subscribe === false) {
          set_is_channel_subscribe(true);
        }

        if (tradeweb_quotes_stream_channel_data.typ === "ask") {
          set_bond_quote_ask_size(tradeweb_quotes_stream_channel_data.sz);
          set_bond_quote_ask_vendor(tradeweb_quotes_stream_channel_data.vid);
          set_buyButton(true);
          set_sellButton(false);
        }
        if (tradeweb_quotes_stream_channel_data.typ === "bid") {
          set_bond_quote_bid_size(tradeweb_quotes_stream_channel_data.sz);
          set_bond_quote_bid_vendor(tradeweb_quotes_stream_channel_data.vid);
          set_sellButton(true);
          set_buyButton(false);
        }
        if (Object.keys(rfq_global_response).length > 0) {
        } else {
          console.log("RFQ:: " + rfq_form.rfq_request_id + " === " + tradeweb_quotes_stream_channel_data.rid);
          if (Number(rfq_form.rfq_request_id) === Number(tradeweb_quotes_stream_channel_data.rid)) {
            console.log("RFQ:: New data ++++---  2 [ok]", tradeweb_quotes_stream_channel_data);
            // console.log("RFQ:: rfq_form data---[ok]", rfq_form);
            streamcount++;
            if (streamcount === 1) {
              set_rfqLabel("RFQ");
            }
            // console.log("RFQ:: streamcount data---[ok]", streamcount);
            if (tradeweb_quotes_stream_channel_data.typ !== undefined && tradeweb_quotes_stream_channel_data.typ === "bid") {
              console.log("RFQ:: New data ++++---  2 [ok] --- IN BID", tradeweb_quotes_stream_channel_data);
              // console.log("RFQ:: streamcount 11 data---[ok]", rfq_form);
              if (rfq_form.step === 2) {
                console.log("RFQ:: New data ++++---  2 [ok] --- IN BID step 2", tradeweb_quotes_stream_channel_data);
                set_timer_request_rfq({
                  ...timer_request_rfq,
                  bid_price: tradeweb_quotes_stream_channel_data.px,
                });
              } else {
                console.log("RFQ:: New data ++++---  2 [ok] --- IN BID step 2 else", tradeweb_quotes_stream_channel_data);
                // console.log("RFQ:: streamcount 22 data---[ok]", rfq_form);
                dispatch(
                  update_rfq_form_data({
                    bid_size: tradeweb_quotes_stream_channel_data.sz,
                    bid_price: tradeweb_quotes_stream_channel_data.px,
                    bid_entry_id: tradeweb_quotes_stream_channel_data.qid,
                  })
                );
                // console.log("RFQ:: streamcount 33 data---[ok]", rfq_form);
              }
              if (Number(tradeweb_quotes_stream_channel_data.px) === 0) {
                set_isRfqBtn(true);
              } else {
                set_isRfqBtn(false);
              }
            }
            if (tradeweb_quotes_stream_channel_data.typ !== undefined && tradeweb_quotes_stream_channel_data.typ === "ask") {
              console.log("RFQ:: New data ++++---  2 [ok] --- IN ASK ", tradeweb_quotes_stream_channel_data);
              // console.log("RFQ:: data 4444 data---[ok]", data);
              // console.log("RFQ:: data 5555 rfq_form.step---[ok]", rfq_form.step);
              if (rfq_form.step === 2) {
                console.log("RFQ:: New data ++++---  2 [ok] --- IN ASK step 2 ", tradeweb_quotes_stream_channel_data);
                set_timer_request_rfq({ ...timer_request_rfq, ask_price: tradeweb_quotes_stream_channel_data.px });
              } else {
                console.log("RFQ:: New data ++++---  2 [ok] --- IN ASK step 2 else ", tradeweb_quotes_stream_channel_data);
                dispatch(
                  update_rfq_form_data({
                    ask_size: tradeweb_quotes_stream_channel_data.sz,
                    ask_price: tradeweb_quotes_stream_channel_data.px,
                    ask_entry_id: tradeweb_quotes_stream_channel_data.qid,
                  })
                );
              }
              if (Number(tradeweb_quotes_stream_channel_data.px) === 0) {
                set_isRfqBtn(true);
              } else {
                set_isRfqBtn(false);
              }
            }
            console.log("RFQ:: New data ++++---  3 [ok] --- final data update ", tradeweb_quotes_stream_channel_data);
            dispatch(
              update_rfq_form_data({
                rfq_request_id: tradeweb_quotes_stream_channel_data.rid,
                settlement_date: tradeweb_quotes_stream_channel_data.tm,
              })
            );
          }
        }
      }
    }
    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [tradeweb_quotes_stream_channel_data, rfq_form.rfq_request_id]);

  const [is_rfq_direction, set_is_rfq_direction] = useState("");
  useEffect(() => {
    (async () => {
      if (is_tradeweb_received) {
        // set_idle_first_rfq_timer(0);
        set_disableRFQForm(true);
        set_disableRFQBtn(true);
        set_cancelButton(true);
        // set_is_tradeweb_received(false);
        // console.log("RFQ:: set_is_tradeweb_received disableRFQBtn----------------------", disableRFQBtn);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [is_tradeweb_received]);
  const handleRFQChangeAmount = (e: any) => {
    // console.log("toggleRfqBtn------ Amount=", formatNumber(e.target.value));
    let resNumber = formatNumber(String(e.target.value));
    if (resNumber !== false) {
      dispatch(update_rfq_form_data({ quantity: resNumber }));
    }
  };
  const handleChangeLimitPrice = (e: any) => {
    if (price_format(e.target.value)) {
      dispatch(update_rfq_form_data({ limit_price: e.target.value }));
    }
  };
  //Handle update for RFQ from chamge amount and price value
  useEffect(() => {
    (async () => {
      // console.log("toggleRfqBtn--------------------------", rfq_form);
      let rfqQty = String(rfq_form.quantity).replace(/[ ,]+/g, "");
      // console.log("toggleRfqBtn--------------------------", rfqQty);
      // console.log("toggleRfqBtn---------------ask_value-----------", ask_value);
      // console.log("toggleRfqBtn---------------bid_value-----------", bid_value);
      if (Number(ask_value) > 0 || Number(bid_value) > 0) {
        if (rfqFormType === "cash") {
          if (
            rfq_form.currencyOne === undefined ||
            rfq_form.currencyOne === null ||
            rfq_form.currencyTwo === undefined ||
            rfq_form.currencyTwo === null ||
            rfqQty === undefined ||
            rfqQty === null ||
            Number(rfqQty) <= 0
          ) {
            set_disableRFQBtn(true);
          } else {
            set_disableRFQBtn(false);
          }
        } else if (rfqFormType === "bond") {
          if (
            rfqQty === undefined ||
            rfqQty === null ||
            Number(rfqQty) <= 0 ||
            rfq_form.limit_price === undefined ||
            rfq_form.limit_price === null ||
            Number(rfq_form.limit_price) <= 0
          ) {
            set_disableRFQBtn(true);
            set_isRfqBtn(true);
          } else {
            set_disableRFQBtn(false);
            set_isRfqBtn(false);
          }
        }
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [rfq_form.quantity, rfq_form.limit_price]);

  const onRFQChangeAmount = (e: any) => {};

  const onRFQChangeLimitPrice = (e: any) => {};
  const toggleRfqBtn = () => {};
  const setRFQDirection = (direction: any) => {
    socket.removeAllListeners(rfq_channel_name);
    let quote_id = null;
    let size = 0;
    let price = 0;
    let provider = null;
    let quote_side = null;
    if (direction === "Buy") {
      if (rfq_form.currencyOne === rfq_form.currency) {
        quote_id = rfq_form.ask_entry_id;
        size = rfq_form.ask_size;
        price = rfq_form.ask_price;
        quote_side = rfq_form.ask_side;
      } else {
        quote_id = rfq_form.bid_entry_id;
        size = rfq_form.bid_size;
        price = rfq_form.bid_price;
        quote_side = rfq_form.bid_side;
      }
      // var rfqDIalogHeader = "Offer Wanted <strong>" + rfq_form.quantity + "</strong> " + default_security_set;
      var rfqDIalogHeader =
        "Offer Wanted in <strong>" +
        rfq_form.quantity +
        "</strong> Nominal of " +
        default_security_set.tabName +
        " (ISIN " +
        default_security_set.isin_code +
        ")";
      set_order_executed_lbl(rfqDIalogHeader);
    } else if (direction === "Sell") {
      if (rfq_form.currencyTwo === rfq_form.currency) {
        quote_id = rfq_form.ask_entry_id;
        size = rfq_form.ask_size;
        price = rfq_form.ask_price;
        quote_side = rfq_form.ask_side;
      } else {
        quote_id = rfq_form.bid_entry_id;
        size = rfq_form.bid_size;
        price = rfq_form.bid_price;
        quote_side = rfq_form.bid_side;
      }
      var rfqDIalogHeader = "Bid Wanted <strong>" + rfq_form.quantity + "</strong> " + isin_code;
      set_order_executed_lbl(rfqDIalogHeader);
    }
    let settlement_type = rfq_form.settlement_type;
    let settlement_date = rfq_form.settlement_date;
    let symbol = rfq_form.symbol;
    let currency = rfq_form.currency;

    dispatch(update_rfq_form_data({ is_calculate: 1, side: direction, direction: direction.toUpperCase() }));
  };
  //START:: place order api manage response
  useEffect(() => {
    (async () => {
      if (is_channel_subscribe === true) {
        // console.log("timer:: reset timer");
        setTimeLeft(0);
        set_isRfqBtn(false);
        startCountdown();
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [is_channel_subscribe]);
  const [counterIntervalId, set_counterIntervalId] = useState<any>(false);
  const [update_rfq_timer, set_update_rfq_timer] = useState<any>("00:00");

  const [current_step, set_current_step] = useState<any>(1);
  const [timeLeft, setTimeLeft] = useState(0);
  useEffect(() => {
    // exit early when we reach 0
    console.log("RFQ:: timer::call return--In", current_step + "==" + is_order_placed + "==timeLeft=" + timeLeft);
    if (!timeLeft) {
      set_update_rfq_timer("00:00");
      // console.log("timer::call return--In", current_step + "==" + is_order_placed + "==timeLeft=" + timeLeft);
      if (Number(current_step) === 4) {
        setTimeout(() => {
          // Prevent duplicates.
          // clearInterval(intervalId);
          set_is_channel_subscribe(false);
          if (!is_order_placed) {
            set_first_timer_dialog(false);
            resetRFQForm();
            cancelRFQRequest(1);
          }
          set_current_step(1);
          clearInterval(intervalId);
          return;
        }, 1000);
      }
      if (rfq_form.trade_way === null || rfq_form.trade_way === undefined) {
        return;
      }
      if (Number(timeLeft) === 0 && (Number(current_step) === 1 || Number(current_step) === 3)) {
        set_is_channel_subscribe(false);
        if (!is_order_placed) {
          set_first_timer_dialog(false);
          resetRFQForm();
        }
        set_current_step(1);
        return;
      }
    }
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      // console.log("timer:: timeLeft", timeLeft + "==" + current_step);
      if (Number(timeLeft) <= 9) {
        set_update_rfq_timer("00:0" + timeLeft);
      } else {
        set_update_rfq_timer("00:" + timeLeft);
      }
      if (Number(timeLeft) === 1) {
        set_current_step(current_step + 1);
      }
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
  useEffect(() => {
    (async () => {
      // console.log("timer:: current_step", current_step + "==" + timeLeft);
      if (Number(current_step) === 2 && Number(timeLeft) === 0) {
        set_current_step(3); // 3
        setTimeLeft(59);
        set_requesting_quotes_title("Expiration Time");
      }
      if (Number(current_step) === 4 && Number(timeLeft) === 0) {
        setTimeLeft(0);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [current_step]);
  const startCountdown = (isnextlevel: boolean = true) => {
    setTimeLeft(59);
  };

  const stopCountdown = () => {
    clearInterval(counterIntervalId);
  };
  const [is_clear_rfq_form, set_is_clear_rfq_form] = useState<any>(false);
  useEffect(() => {
    (async () => {
      if (is_clear_rfq_form === true) {
        set_idle_first_rfq_timer(0);
        set_is_third_link_open(false);
        set_timer(0);
        set_disableRFQForm(false);
        set_disableRFQBtn(false);
        set_isRfqBtn(true);
        set_rfq_current_step(1);
        // console.log("toggleRfqBtn------ is_clear_rfq_form----[ok]", rfq_form);
        let d_vid = 0;
        const current_market_id = localStorage.getItem("current_market_id");
        if (clientVendors) {
          const foundObject: any = clientVendors.find((item: any) => Number(item.market_type_id) === Number(current_market_id));
          if (foundObject !== undefined) {
            if (Number(current_market_id) !== 5) {
              d_vid = foundObject.vendor_id;
            } else {
              d_vid = foundObject.vendor_id;
            }
          }
        }
        if (loggedType === "internal" && d_vid === 0 && Number(current_market_id) === 5) {
          d_vid = 8;
        }
        if (loggedType === "internal" && d_vid === 0 && Number(current_market_id) !== 5) {
          d_vid = 2;
        }
        dispatch(
          update_rfq_form_data({
            // ...rfq_form,
            timeout_action: 1,
            step: 1,
            portfolio_id: 0,
            rfq_form_type: "bond",
            security_id: Number(localStorage.getItem("current_security_id")),
            is_calculate: 1,
            currencyOne: null,
            currencyTwo: null,
            currency: null,
            settlementType: "SPOT",
            settlement_type: "SPOT",
            settlement_date: "",
            bid_price: 0,
            ask_price: 0,
            bid_size: 0,
            ask_size: 0,
            bid_side: null,
            ask_side: null,
            rate: 0,
            direction: "",
            side: "",
            quote_side: null,
            //timer: "00:00",
            quantity: "0",
            limit_price: 0,
            bid_entry_id: null,
            ask_entry_id: null,
            symbol: null,
            lock_quote: {},

            type: "limit",
            day: "day",
            rfq_request_id: "",
            order_executed_id: "",
            allocates_input_fees: 0,
            allocates_input_set_fees: 0,
            trade_way: "",
            is_zero_price: false,
            allocate_portfolios: [
              {
                client_id: null,
                qty: "0",
                percentage: "",
                fees: 0,
                set_fees: 0,
                markup_fees: 0,
                amount: 0,
                markup_type: "abs",
                markup_value: 0,
                est_markup: 0,
                inverse_convert_amt_display: 0,
                inverse_currency_amt_display: 0,
                markup_price: 0,
              },
            ],
            filter_client_id: 0,
            filter_client_qty: 0,
            vendor: d_vid,
          })
        );

        set_timer_request_rfq({
          // ...timer_request_rfq,
          bid_price: 0,
          ask_price: 0,
        });
        let timer_request_rfq = {
          bid_price: 0,
          ask_price: 0,
        };
        set_timer_request_rfq(timer_request_rfq);
        set_requesting_quotes_title("Requesting Quotes");
        set_rfqLabel("RFQ");
        set_lift_btn_label("Lift");
        set_hit_btn_label("Hit");
        set_order_message("");
        set_is_btn_txt_change(false);

        let rfqChannelName = rfq_channel_name ? rfq_channel_name : "fxspot-quotes";
        socket.removeAllListeners(rfqChannelName);
        stopCountdown();
        set_is_clear_rfq_form(false);
        set_confirm({
          account_name: null,
          amount: 0,
          security_name: null,
          isin: null,
          direction: null,
          type: "MARKET",
          quantity: 0,
          price: 0,
          fees: 0,
          net_consideration: 0,
          time: "day",
          currency: "",
        });
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [is_clear_rfq_form]);

  const resetRFQForm = () => {
    // console.log("RFQ:: resetRFQForm");
    set_current_step(1);
    setTimeLeft(0);
    set_is_clear_rfq_form(true);
  };
  const startstreamForResponse = () => {};
  useEffect(() => {
    (async () => {
      if (orderexecutedid) {
        dispatch(update_rfq_form_data({ order_executed_id: orderexecutedid }));
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [orderexecutedid]);
  const last_step_execution = () => {
    let rfq_gl_response = rfq_global_response;
    set_orderexecutedid(rfq_global_response.order_executed_id);
    if (allocate_portfolio.length > 1) {
      // console.log("RFQ:: allocate_portfolio", allocate_portfolio.length);
      set_is_order_placed(true);
    } else {
      set_first_timer_dialog(false);
      toast.success("Order Placed", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    /**** transection added on list */
    dispatch(is_update_transaction_data(true));
    set_net_consideration(0);
    set_fees(0);
  };

  const dummyclick = (e: any) => {};
  const handleRFQFormpercentage = (e: any, index: any) => {
    dispatch(
      update_form_allocates_inputs({ index: index, field: "percentage", value: e.target.value, mktid: localStorage.getItem("current_market_id") })
    );
    // dispatch(
    //   update_map_allocates_inputs({
    //     allocates_inputs: allocates_inputs,
    //     percentage: e.target.value,
    //     updated_index: index,
    //     form_type: false,
    //     set_fees: responseGetFees.set_fees,
    //     fees: responseGetFees.fees,
    //   })
    // );
  };
  const handleRFQFormMarkupFees = (e: any, index: any) => {
    dispatch(
      update_form_allocates_inputs({ index: index, field: "markup_fees", value: e.target.value, mktid: localStorage.getItem("current_market_id") })
    );
    // dispatch(
    //   update_map_allocates_inputs({
    //     allocates_inputs: allocates_inputs,
    //     markup_fees: e.target.value,
    //     updated_index: index,
    //     form_type: false,
    //     set_fees: responseGetFees.set_fees,
    //     fees: responseGetFees.fees,
    //   })
    // );
  };
  const handleFormpercentage = (e: any, index: any) => {
    dispatch(
      update_form_allocates_inputs({ index: index, field: "percentage", value: e.target.value, mktid: localStorage.getItem("current_market_id") })
    );
    // dispatch(
    //   update_map_allocates_inputs({
    //     allocates_inputs: allocates_inputs,
    //     percentage: e.target.value,
    //     updated_index: index,
    //     form_type: true,
    //     set_fees: responseGetFees.set_fees,
    //     fees: responseGetFees.fees,
    //   })
    // );
    // dispatch(update_form_data({ allocate_portfolios: allocates_inputs }));
  };
  const handleFormMarkupFees = (e: any, index: any) => {
    if (price_format(e.target.value)) {
      dispatch(
        update_form_allocates_inputs({ index: index, field: "markup_fees", value: e.target.value, mktid: localStorage.getItem("current_market_id") })
      );
      // dispatch(
      //   update_map_allocates_inputs({
      //     allocates_inputs: allocates_inputs,
      //     markup_fees: e.target.value,
      //     updated_index: index,
      //     form_type: true,
      //     set_fees: responseGetFees.set_fees,
      //     fees: responseGetFees.fees,
      //   })
      // );
    }
    // dispatch(update_form_data({ allocate_portfolios: allocates_inputs }));
  };

  const handleFormamount = (e: any, index: any) => {
    let qty = formatNumber(String(e.target.value));
    if (qty !== false) {
      dispatch(update_form_allocates_inputs({ index: index, field: "amount", value: qty, mktid: localStorage.getItem("current_market_id") }));
      var updated_amount = e.target.value;
      var partialValue = typeof updated_amount === "string" ? Number(updated_amount.replace(/,/g, "")) : Number(updated_amount);
      var totalValue = typeof form.fund_amount === "string" ? Number(String(form.fund_amount).replace(/,/g, "")) : Number(form.fund_amount);

      var percentage = (100 * partialValue) / totalValue;
      // console.log("percentage", percentage);
      dispatch(
        update_form_allocates_inputs({
          index: index,
          field: "percentage",
          value: percentage.toFixed(0),
          mktid: localStorage.getItem("current_market_id"),
        })
      );
      // dispatch(
      //   update_map_allocates_inputs({
      //     allocates_inputs: allocates_inputs,
      //     amount: qty,
      //     updated_index: index,
      //     form_type: true,
      //     set_fees: responseGetFees.set_fees,
      //     fees: responseGetFees.fees,
      //   })
      // );
    }
  };

  const handleFormQty = (e: any, index: any, client_id: any) => {
    set_is_call_get_fees(true);
    if (price_format(e.target.value)) {
      dispatch(update_form_data({ filter_client_id: client_id }));
      dispatch(update_form_data({ filter_client_qty: e.target.value }));
      set_disabled_confirm(1);
      dispatch(update_form_allocates_inputs({ index: index, field: "qty", value: e.target.value, mktid: localStorage.getItem("current_market_id") }));
      var updated_qty = e.target.value;
      var partialValue = typeof updated_qty === "string" ? Number(updated_qty.replace(/,/g, "")) : Number(updated_qty);
      var totalValue = typeof form.quantity === "string" ? Number(form.quantity.replace(/,/g, "")) : Number(form.quantity);
      var percentage = (100 * partialValue) / totalValue;

      dispatch(
        update_form_allocates_inputs({
          index: index,
          field: "percentage",
          value: percentage.toFixed(0),
          mktid: localStorage.getItem("current_market_id"),
        })
      );

      // dispatch(
      //   update_map_allocates_inputs({
      //     allocates_inputs: allocates_inputs,
      //     qty: e.target.value,
      //     updated_index: index,
      //     form_type: true,
      //     set_fees: responseGetFees.set_fees,
      //     fees: responseGetFees.fees,
      //   })
      // );
    }
  };
  const handleRfqQty = (e: any, index: any, client_id: any) => {
    set_disabled_confirm(1);
    dispatch(update_form_data({ filter_client_qty: e.target.value }));
    dispatch(update_form_data({ filter_client_id: client_id }));
    dispatch(update_form_allocates_inputs({ index: index, field: "qty", value: e.target.value, mktid: localStorage.getItem("current_market_id") }));
    var updated_qty = e.target.value;
    var partialValue = typeof updated_qty === "string" ? Number(updated_qty.replace(/,/g, "")) : Number(updated_qty);
    var totalValue = typeof rfq_form.quantity === "string" ? Number(rfq_form.quantity.replace(/,/g, "")) : Number(rfq_form.quantity);
    var percentage = (100 * partialValue) / totalValue;

    dispatch(
      update_form_allocates_inputs({
        index: index,
        field: "percentage",
        value: percentage.toFixed(2),
        mktid: localStorage.getItem("current_market_id"),
      })
    );
    // setChangeQty(e.target.value);
    // dispatch(
    //   update_map_allocates_inputs({
    //     allocates_inputs: allocates_inputs,
    //     qty: e.target.value,
    //     updated_index: index,
    //     form_type: false,
    //     set_fees: responseGetFees.set_fees,
    //     fees: responseGetFees.fees,
    //   })
    // );
  };

  const handleallocatesInputFees = (e: any, index: any) => {
    const regex = /^[0-9.,]*$/;
    if (price_format(e.target.value) && e.target.value.length < 12 && regex.test(e.target.value)) {
      if (isRFQForm) {
        dispatch(update_rfq_form_data({ allocates_input_fees: e.target.value }));
        dispatch(
          update_form_allocates_inputs({ index: index, field: "fees", value: e.target.value, mktid: localStorage.getItem("current_market_id") })
        );
      } else {
        dispatch(update_form_data({ allocates_input_fees: e.target.value }));
        dispatch(
          update_form_allocates_inputs({ index: index, field: "fees", value: e.target.value, mktid: localStorage.getItem("current_market_id") })
        );
      }
    }
  };
  const handleallocatesInputSetFees = (e: any, index: any) => {
    const regex = /^[0-9.,]*$/;
    if (price_format(e.target.value) && e.target.value.length < 12 && regex.test(e.target.value)) {
      if (isRFQForm) {
        dispatch(update_rfq_form_data({ allocates_input_set_fees: e.target.value }));
        dispatch(
          update_form_allocates_inputs({ index: index, field: "set_fees", value: e.target.value, mktid: localStorage.getItem("current_market_id") })
        );
      } else {
        dispatch(update_form_data({ allocates_input_set_fees: e.target.value }));
        dispatch(
          update_form_allocates_inputs({ index: index, field: "set_fees", value: e.target.value, mktid: localStorage.getItem("current_market_id") })
        );
      }
    }
  };
  const handleRfqallocatesInputFees = (e: any) => {
    dispatch(update_rfq_form_data({ allocates_input_fees: e.target.value }));
  };
  const handleRfqallocatesInputSetFees = (e: any) => {
    dispatch(update_rfq_form_data({ allocates_input_set_fees: e.target.value }));
  };
  const cancelRFQRequest = (timeout_action: any) => {
    if (!is_order_placed) {
      let paylod = {
        request_uid: rfq_form.rfq_request_id,
        side: rfq_form.direction,
        security_id: Number(localStorage.getItem("current_security_id")),
      };
      // console.log("timer:: cancelRFQ Request RFQ API", paylod);
      dispatch(cancelRFQ(paylod));
      setIsConfirmClose(true);
    }
  };
  const openconfirmbox = (e: any) => {
    // if (allocate_portfolio.length > 1) {
    set_is_order_placed(true);

    setTimeLeft(0);
    dispatch(update_form_allocates_inputs({ index: 0, field: "qty", value: rfq_form.quantity, mktid: localStorage.getItem("current_market_id") }));
    // dispatch(
    //   update_map_allocates_inputs({
    //     allocates_inputs: allocates_inputs,
    //     qty: rfq_form.quantity,
    //     updated_index: 0,
    //     form_type: false,
    //     set_fees: responseGetFees.set_fees,
    //     fees: responseGetFees.fees,
    //   })
    // );
    // }
  };
  const first_timer_dialog_cancel = (timeout_action: any) => {
    // alert("hello");
    set_is_order_placed(false);
    let timer_request_rfq = {
      bid_price: 0,
      ask_price: 0,
    };
    set_is_channel_subscribe(false);
    set_timer_request_rfq(timer_request_rfq);
    dispatch(clear_form_allocates_inputs());
    setTimeLeft(0);
    set_message_type("");
    set_order_status("");
    set_order_message("");
    set_first_timer_dialog(false);
    if (rfq_form.rfq_request_id) {
      cancelRFQRequest(1);
    }
    dispatch(clear_tradeweb_quotes_stream_channel_data(""));
    // dispatch(update_tradeweb_quotes_stream_channel_data({}));
    let d_vid = 0;
    const current_market_id = localStorage.getItem("current_market_id");
    if (clientVendors) {
      const foundObject: any = clientVendors.find((item: any) => Number(item.market_type_id) === Number(current_market_id));
      if (foundObject !== undefined) {
        if (Number(current_market_id) !== 5) {
          d_vid = foundObject.vendor_id;
        } else {
          d_vid = foundObject.vendor_id;
        }
      }
    }
    if (loggedType === "internal" && d_vid === 0 && Number(current_market_id) === 5) {
      d_vid = 8;
    }
    if (loggedType === "internal" && d_vid === 0 && Number(current_market_id) !== 5) {
      d_vid = 2;
    }
    set_is_zero_link_click_loading("Click here");
    set_zero_price_link(false);
    set_idle_first_rfq_timer(0);
    set_is_third_link_open(false);
    dispatch(
      update_rfq_form_data({
        step: 1,
        rfq_request_id: 0,
        is_zero_price: false,
        vendor: d_vid,
        limit_price: 0,
        quantity: "0",
        lock_quote: {},
        security_id: 0,
        allocate_portfolios: [
          {
            client_id: null,
            qty: "0",
            percentage: "",
            fees: 0,
            set_fees: 0,
            markup_fees: 0,
            amount: 0,
          },
        ],
        trade_way: null,
      })
    );
    set_rfq_current_step(1);
    set_disableRFQBtn(false);
    set_cancelButton(false);
    set_sellButton(false);
    set_buyButton(false);
    set_rfqButton(true);
  };
  //END:: place order api manage response
  // END RFQ Request
  //select change events
  const handleVendorChange = (e: any) => {
    dispatch(update_form_data({ vendor: e.value }));
    if (e.value === 7) {
      let timer_request_rfq = {
        bid_price: 0,
        ask_price: 0,
      };
      set_timer_request_rfq(timer_request_rfq);
      dispatch(update_form_data({ order_type: "market" }));
      dispatch(update_form_data({ day: "good_till_cancel" }));
    }
  };
  const handleBondVendorChange = (e: any) => {
    localStorage.setItem("current_reqvid_id", e.value);
    if (Number(e.value) === 2 || Number(e.value) === 9 || Number(e.value) === 10) {
      // IB, Intesa and TSOX price data null and update from object for rfs_lock_quote
      set_bond_quote_ask_size(0);
      set_bond_quote_ask_vendor(e.value);
      let payload = {
        ask_price: 0,
      };
      dispatch(update_ask_prices(payload));

      set_bond_quote_bid_size(0);
      set_bond_quote_bid_vendor(e.value);

      let payloadBid = {
        bid_price: 0,
      };
      dispatch(update_bid_prices(payloadBid));

      let last_quote_update = {};
      dispatch(update_rfq_form_data({ rfs_lock_quote: last_quote_update }));
    }
    dispatch(update_rfq_form_data({ vendor: e.value }));
    streamChannel(default_security_set, e.value);
  };
  const getVendorTitle = (id: any, from: string) => {
    console.log("getVendorTitle::", id);
    const item = vendorsoptions.find((item: any) => item.value === id);
    console.log("getVendorTitle:: item", vendorsoptions);
    console.log("getVendorTitle:: from", from);
    let title = item ? item.label : "Title Not Found";

    console.log("getVendorTitle:: ---- title", title);
    return title;
  };

  const getForexVendorTitle = (id: any, from: string) => {
    // console.log("getForexVendorTitle::", id);
    const item = forex_vendor_option.find((item: any) => item.value === id);
    // console.log("getForexVendorTitle:: item", forex_vendor_option);
    // console.log("getForexVendorTitle:: from", from);
    let title = item ? item.label : "Title Not Found";
    // if (Number(form.vendor) === 8 && from === "modal") {
    //   let getbestQuotevendorID =
    //   form.direction.toLowerCase() === "buy" ? rfq_form.rfs_lock_quote.askVendorId : rfq_form.rfs_lock_quote.bidVendorId;
    //   const subitem = forex_vendor_option.find((item: any) => item.value === getbestQuotevendorID);
    //   let subtitle = subitem ? subitem.label : "";
    //   title = subtitle;
    // }
    // console.log("getForexVendorTitle:: ---- title", title);
    return title;
  };
  const getBondVendorTitle = (id: any, from: string) => {
    console.log("getBondVendorTitle::", id);
    const item = bond_vendor_option.find((item: any) => item.value === id);
    console.log("getBondVendorTitle:: item", bond_vendor_option);
    console.log("getBondVendorTitle:: from", from);
    let title = item ? item.label : "Title Not Found";
    if (Number(rfq_form.vendor) === 8 && from === "modal") {
      let getbestQuotevendorID =
        rfq_form.direction.toLowerCase() === "buy" ? rfq_form.rfs_lock_quote.askVendorId : rfq_form.rfs_lock_quote.bidVendorId;
      const subitem = bond_vendor_option.find((item: any) => item.value === getbestQuotevendorID);
      let subtitle = subitem ? subitem.label : "";
      title = subtitle;
    }
    console.log("getBondVendorTitle:: ---- title", title);
    return title;
  };

  const handleMarkupChange = (client_id: any, e: any, index: any, qty: any) => {
    console.log("handleMarkupChange::", e.value);

    setMarkuptype(e.value);
    // dispatch(update_form_data({ vendor: e.value }));
    // dispatch(update_form_data({ allocate_portfolios: allocates_inputs }));
    set_is_call_get_fees(false);
    dispatch(update_form_data({ filter_client_id: client_id }));
    dispatch(update_form_data({ filter_client_qty: qty }));
    dispatch(update_form_allocates_inputs({ index: index, field: "client_id", value: client_id, mktid: localStorage.getItem("current_market_id") }));
    dispatch(update_form_allocates_inputs({ index: index, field: "markup_type", value: e.value, mktid: localStorage.getItem("current_market_id") }));

    // dispatch(
    //   update_map_allocates_inputs({
    //     allocates_inputs: allocates_inputs,
    //     form_type: true,
    //     set_fees: responseGetFees.set_fees,
    //     fees: responseGetFees.fees,
    //     client_id: client_id,
    //     markup_type: e.value,
    //     updated_index: index,
    //     // markup_value: responseGetFees.mark_up_data.value,
    //   })
    // );
  };
  const [is_change_markup_data, set_is_change_markup_data] = useState(false);
  // call get markup data API
  useEffect(() => {
    (async () => {
      if (is_change_markup_data) {
        dispatch(getClientMarkup(form));
        set_is_change_markup_data(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [is_change_markup_data]);
  useEffect(() => {
    if (clientMarkupData) {
      set_disabled_confirm(0);
      set_confirm_btn_labl("Submit");
      console.log("clientMarkupData", clientMarkupData);

      dispatch(
        update_form_allocates_inputs({
          client_id: clientMarkupData.client_id,
          field: "inverse_convert_amt_display",
          value: Number(clientMarkupData.convert_amount),
          mktid: 6,
        })
      );
      dispatch(
        update_form_allocates_inputs({
          client_id: clientMarkupData.client_id,
          field: "inverse_currency_amt_display",
          value: Number(clientMarkupData.new_currency_amount),
          mktid: 6,
        })
      );
      dispatch(
        update_form_allocates_inputs({
          client_id: clientMarkupData.client_id,
          field: "est_markup",
          value: Number(clientMarkupData.origin_markup),
          mktid: 6,
        })
      );
      dispatch(
        update_form_allocates_inputs({
          client_id: clientMarkupData.client_id,
          field: "markup_price",
          value: Number(clientMarkupData.price),
          mktid: 6,
        })
      );
      // dispatch(update_client_markup_data([]));
    }

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [clientMarkupData]);
  // setTimeout(() => {
  //   set_is_change_markup_data(true);
  //   // dispatch(
  //   //   getClientMarkup({
  //   //     client_id: allocates_input.client_id,
  //   //     markup_type: allocates_input.markup_type,
  //   //     markup_value: allocates_input.markup_value,
  //   //   })
  //   // );
  // }, 1000);
  const [is_change_event, set_is_change_event] = useState(false);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      set_is_change_markup_data(true);
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
      set_is_change_event(false);
    };
  }, [is_change_event]);
  const handleMarkupValueChange = (client_id: any, e: any, index: any, qty: any) => {
    const regex = /^[0-9.,]*$/;
    if (e.target.value.length < 12 && regex.test(e.target.value)) {
      set_is_call_get_fees(false);
      console.log("handleMarkupValueChange:: value", e.target.value);
      dispatch(update_form_data({ filter_client_id: client_id }));
      dispatch(update_form_data({ filter_client_qty: qty }));
      setMarkuptypevalue(e.target.value);
      dispatch(
        update_form_allocates_inputs({ index: index, field: "client_id", value: client_id, mktid: localStorage.getItem("current_market_id") })
      );
      dispatch(
        update_form_allocates_inputs({ index: index, field: "markup_value", value: e.target.value, mktid: localStorage.getItem("current_market_id") })
      );
    }
    if (e.target.value === undefined || e.target.value === null || e.target.value.trim() === "") {
      console.log("onChange markupvalue 1", e.target.value);
      dispatch(update_form_allocates_inputs({ index: index, field: "markup_value", value: 0, mktid: localStorage.getItem("current_market_id") }));
    }
    set_is_change_event(true);
    // setTimeout(() => {
    //   dispatch(getClientMarkup({ updateIndex: 0, allocate_portfolios: allocates_inputs }));
    // }, 1000);
  };
  const OrderConfirmationModalref: any = useRef();
  useOnClickOutside(OrderConfirmationModalref, () => {
    // console.log("clicked");
    //confirmClose();
  });
  const addToWatchlist = (e: any) => {
    dispatch(updateIsShowAddtoWatchList(true));
  };
  return (
    <>
      {loading ? (
        <Loading isMaximized={"minimize"} />
      ) : (
        <>
          {!is_load_security ? (
            <span className="watchlist-nodata">{is_load_security_message}</span>
          ) : (
            <div
              style={{
                height: `${height}`,
                // width: `${width}px`,
                overflow: "auto",
              }}
              className="bg-layoutBg flex flex-col order-form-group"
            >
              <div className="order-form-header sticky top-0 z-10  bg-layoutBg">
                <AutoCompleteSearchBox is_load_change={is_load_change} />
                <div>
                  <div className="order-item-name">
                    <h3>{default_security_set.tabName}</h3>
                    <div className="action-btn">
                      {/* <Svg
                    onClickMethod={addToWatchlist}
                    type="plus"
                    classString="show-data-icon"
                    width="17"
                    title="Add to watchlist"
                    fillColor="#071932"
                  /> */}
                      {showInformationWindowFlag ? (
                        <img
                          className="show-data-icon close-icon"
                          onClick={() => {
                            handleSecurityDetailWindow(false);
                          }}
                          src={closeIcon}
                          alt=""
                        />
                      ) : (
                        <img
                          className="show-data-icon"
                          onClick={() => {
                            handleSecurityDetailWindow(true);
                          }}
                          src={Eyeicon}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  {/* {showInformationWindowFlag && <InformationWindow refProp={ref} />} */}
                </div>
              </div>
              {!showInformationWindowFlag ? (
                <div className="order-form-body">
                  {/* START::Not isRFQForm for forex and equity*/}
                  {!isRFQForm && Number(default_security_set.market_id) !== 8 && Number(default_security_set.market_id) !== 0 && (
                    <form onSubmit={onSubmit}>
                      <div className="">
                        {/* Equity vendor */}
                        {showVendor && showVendorOption && loggedType === "internal" && Number(default_security_set.market_id) === 1 && (
                          <div className="order-form-select-input custome-select w-full mb-06vw ">
                            <label className="select-lable">Broker</label>
                            <Select
                              styles={DropDownStylesBroker}
                              // value={"Interactive"}
                              placeholder="Broker"
                              options={vendorsoptions}
                              onChange={(e: any) => handleVendorChange(e)}
                              isSearchable={false}
                              // menuPlacement={"top"}
                              value={{
                                value: form.vendor,
                                // label: Number(form.vendor) === 2 ? "Interactive" : "Instinet",
                                label: getVendorTitle(form.vendor, "select"),
                              }}
                            />
                          </div>
                        )}
                        {/* forex vendor */}
                        {forex_vendor_option &&
                          show_forex_vendor_dropdown &&
                          loggedType === "internal" &&
                          Number(default_security_set.market_id) === 6 && (
                            <div className="order-form-select-input custome-select w-full mb-06vw ">
                              <label className="select-lable">Broker</label>
                              <Select
                                styles={DropDownStylesBroker}
                                // value={"Interactive"}
                                placeholder="Broker"
                                options={forex_vendor_option}
                                onChange={(e: any) => handleVendorChange(e)}
                                isSearchable={false}
                                // menuPlacement={"top"}
                                value={{
                                  value: form.vendor,
                                  // label: Number(form.vendor) === 2 ? "Interactive" : "Instinet",
                                  label: getForexVendorTitle(form.vendor, "select"),
                                }}
                              />
                            </div>
                          )}
                        <div className="bid-ask-group 3">
                          <div className="bid-ask-box bid-box">
                            <span>BID</span>
                            <h3>
                              {bid_value} <img className="bid-icon" src={bidIcon} alt="" />
                            </h3>
                          </div>
                          <div className="bid-ask-box ask-box">
                            <span>ASK</span>{" "}
                            <h3>
                              <img className="ask-icon" src={askIcon} alt="" />
                              {ask_value}
                            </h3>
                          </div>
                          <span className="bid-ask-difference">{rate_value}</span>
                        </div>
                        <div className="order-form-main">
                          {Number(form.vendor) !== 7 && (
                            <>
                              <div className="order-form-select-input custome-select">
                                <label className="select-lable">Order Type</label>
                                <Select
                                  styles={DropDownStyles2}
                                  placeholder="Order Type"
                                  value={{
                                    value: form.order_type,
                                    label: form.order_type.replace(/[_]+/g, " ").toUpperCase(),
                                  }}
                                  onChange={(e: any) => onChangeOrderType(e)}
                                  options={limits}
                                  isSearchable={false}
                                />
                              </div>
                              <div className="order-form-select-input custome-select">
                                <label className="select-lable">Time</label>
                                <Select
                                  styles={DropDownStyles2}
                                  placeholder="Time"
                                  value={{
                                    value: form.day,
                                    label: form.day === "good_till_cancel" ? "GTC" : form.day.toUpperCase(),
                                  }}
                                  onChange={(e: any) => handleChangeDay(e)}
                                  options={days}
                                  isSearchable={false}
                                />
                              </div>
                            </>
                          )}
                          {/* Equals order type disable */}
                          {Number(form.vendor) === 7 && (
                            <>
                              <div className="order-form-select-input custome-select select-disabled">
                                <label className="select-lable">Order Type</label>
                                <Select
                                  placeholder="Type"
                                  value={{
                                    value: form.order_type,
                                    label: form.order_type.replace(/[_]+/g, " ").toUpperCase(),
                                  }}
                                  options={limits}
                                  isSearchable={false}
                                  isDisabled={true}
                                  styles={DropDownStyles2}
                                />
                              </div>
                              <div className="order-form-select-input custome-select select-disabled">
                                <label className="select-lable">Time</label>
                                <Select
                                  styles={DropDownStyles2}
                                  placeholder="Time"
                                  value={{
                                    value: "good_till_cancel",
                                    label: "GTC",
                                  }}
                                  onChange={(e: any) => handleChangeDay(e)}
                                  options={days}
                                  isSearchable={false}
                                  isDisabled={true}
                                />
                              </div>
                            </>
                          )}

                          {Number(default_security_set.market_id) === 6 && (
                            <div className={`order-form-select-input p-relative ${qtyCssClass}`}>
                              {/* UpdateNetConsideration */}
                              <TextBox
                                label="Quantity"
                                value={form.quantity}
                                onChange={(e: any) => handleChangeQuantity(e)}
                                onKeyUp={(e: any) => updateButtonEvent(e)}
                              />
                              <span className="fx-input-switch" onClick={(e: any) => inverceCurrency(displayCurrency)}>
                                {displayCurrency}
                              </span>
                            </div>
                          )}
                          {Number(default_security_set.market_id) === 1 && (
                            <div className={`order-form-select-input ${qtyCssClass}`}>
                              {/* UpdateNetConsideration */}
                              <TextBox
                                label="Quantity"
                                value={form.quantity}
                                onChange={(e: any) => handleChangeQuantity(e)}
                                onKeyUp={(e: any) => updateButtonEvent(e)}
                              />
                            </div>
                          )}

                          {stopPriceFieldState && (
                            <div className="order-form-select-input">
                              {/* UpdateNetConsideration */}
                              <TextBox label={"Stop Price"} value={form.stop_price} onChange={(e: any) => handleChangeStopPrice(e)} />
                            </div>
                          )}
                          {priceFieldState && (
                            <div className="order-form-select-input">
                              {/* UpdateNetConsideration */}
                              <TextBox label={"Limit Price"} value={form.price} onChange={(e: any) => handleChangePrice(e)} />
                            </div>
                          )}

                          {displayQuantityFieldState && (
                            <div className="order-form-select-input">
                              {/* UpdateNetConsideration */}
                              <TextBox
                                label={"Display Quantity"}
                                value={form.display_quantity}
                                onChange={(e: any) => handleChangeDisplayQuantity(e)}
                              />
                            </div>
                          )}
                        </div>
                        <div className="order-btn-group">
                          <div className="w-full button-box">
                            <Button
                              type="submit"
                              varient={"red"}
                              CTA={"Sell"}
                              disabled={sell_btn_disabled}
                              handleClick={() => setDirection("Sell")}
                            />
                            {form.direction === "Sell" && place_order_status === "pending" && (
                              <div className="order-btton-loader-box">
                                <img className="order-search-loader" src={WhiteLoader} alt="" />
                              </div>
                            )}
                          </div>
                          <div className="w-full button-box">
                            <Button type="submit" varient={"green"} CTA={"Buy"} disabled={buy_btn_disabled} handleClick={() => setDirection("Buy")} />
                            {form.direction === "Buy" && place_order_status === "pending" && (
                              <div className="order-btton-loader-box">
                                <img className="order-search-loader" src={WhiteLoader} alt="" />
                              </div>
                            )}
                          </div>

                          <Button
                            inputRef={(input: any) => (inputButtonElement = input)}
                            className="hidden "
                            id={formName}
                            type="submit"
                            varient={form.direction === "Buy" ? "green" : "red"}
                            CTA={form.direction}
                            handleClick={() => hiddenclickFire("Sell")}
                          />
                        </div>
                      </div>

                      {order_confirm_dialog && (
                        <OrderConfirmationModal
                          Componentref={OrderConfirmationModalref}
                          show={order_confirm_dialog}
                          confirmClose={confirmClose}
                          header={"Order Confirmation"}
                        >
                          {/* For Equity */}
                          {form.isInverse === false && form.direction.toLowerCase() === "buy" && Number(default_security_set.market_id) === 1 && (
                            <EQBOrderConfirmationDetail confirm={confirm} ask_value={ask_value} />
                          )}
                          {form.isInverse === false && form.direction.toLowerCase() === "sell" && Number(default_security_set.market_id) === 1 && (
                            <EQSOrderConfirmationDetail confirm={confirm} bid_value={bid_value} />
                          )}
                          {/* For Forex */}
                          {Number(form.vendor) === 2 && (
                            <>
                              {form.isInverse === false && form.direction.toLowerCase() === "buy" && Number(default_security_set.market_id) === 6 && (
                                <FXBOrderConfirmationDetail
                                  confirm={confirm}
                                  primaryCurrecnyValue={primaryCurrecnyValue}
                                  secondaryCurrecnyValue={secondaryCurrecnyValue}
                                  ask_value={ask_value}
                                  bid_value={bid_value}
                                  userType={loggedType}
                                  selectedPortfolios={allocates_inputs}
                                  allocate_portfolio={allocate_portfolio}
                                  custodianName={getForexVendorTitle(form.vendor, "select")}
                                  clientMarkupData={clientMarkupData}
                                />
                              )}
                              {form.isInverse === false &&
                                form.direction.toLowerCase() === "sell" &&
                                Number(default_security_set.market_id) === 6 && (
                                  <FXSOrderConfirmationDetail
                                    confirm={confirm}
                                    primaryCurrecnyValue={primaryCurrecnyValue}
                                    secondaryCurrecnyValue={secondaryCurrecnyValue}
                                    ask_value={ask_value}
                                    bid_value={bid_value}
                                    userType={loggedType}
                                    selectedPortfolios={allocates_inputs}
                                    allocate_portfolio={allocate_portfolio}
                                    custodianName={getForexVendorTitle(form.vendor, "select")}
                                    clientMarkupData={clientMarkupData}
                                  />
                                )}
                              {form.isInverse === true && form.direction.toLowerCase() === "buy" && Number(default_security_set.market_id) === 6 && (
                                <FXBIOrderConfirmationDetail
                                  confirm={confirm}
                                  primaryCurrecnyValue={primaryCurrecnyValue}
                                  secondaryCurrecnyValue={secondaryCurrecnyValue}
                                  ask_value={ask_value}
                                  bid_value={bid_value}
                                  userType={loggedType}
                                  selectedPortfolios={allocates_inputs}
                                  allocate_portfolio={allocate_portfolio}
                                  custodianName={getForexVendorTitle(form.vendor, "select")}
                                  clientMarkupData={clientMarkupData}
                                />
                              )}
                              {form.isInverse === true && form.direction.toLowerCase() === "sell" && Number(default_security_set.market_id) === 6 && (
                                <FXSIOrderConfirmationDetail
                                  confirm={confirm}
                                  primaryCurrecnyValue={primaryCurrecnyValue}
                                  secondaryCurrecnyValue={secondaryCurrecnyValue}
                                  ask_value={ask_value}
                                  bid_value={bid_value}
                                  userType={loggedType}
                                  selectedPortfolios={allocates_inputs}
                                  allocate_portfolio={allocate_portfolio}
                                  custodianName={getForexVendorTitle(form.vendor, "select")}
                                  clientMarkupData={clientMarkupData}
                                />
                              )}
                            </>
                          )}
                          {/* For Forex Equals */}
                          {Number(form.vendor) === 7 && (
                            <div className="ticker-detail-wrap first_timer_dialog order-form-group fx-quote">
                              <div className="fx-offer-wanted-wrap">
                                <div className={isRfqBtn ? "disabled-box fx-offer-wanted-box" : "fx-offer-wanted-box"}>
                                  <span className="fx-ticker-price-text">Expiration Time</span>
                                  <span className="fx-ticker-price-value">00:{seconds === 10 ? seconds : "0" + seconds}</span>
                                </div>
                                {form.direction.toLowerCase() === "sell" && (
                                  <div className={isRfqBtn ? "disabled-box fx-offer-wanted-box" : "fx-offer-wanted-box"}>
                                    <span className="fx-ticker-price-text">Bid</span>
                                    {timer_request_rfq.bid_price !== 0 && (
                                      <span className={add_blink ? "is_blinking sell-color" : "sell-color"}>
                                        {Number(timer_request_rfq.bid_price).toFixed(5)}
                                      </span>
                                    )}
                                    {timer_request_rfq.bid_price === 0 && <span className="fx-ticker-price-value">N/A</span>}
                                  </div>
                                )}
                                {form.direction.toLowerCase() === "buy" && (
                                  <div className={isRfqBtn ? "disabled-box fx-offer-wanted-box" : "fx-offer-wanted-box"}>
                                    <span className="fx-ticker-price-text">Ask</span>
                                    {timer_request_rfq.ask_price !== 0 && (
                                      <span className={add_blink ? "is_blinking buy-color" : "buy-color"}>
                                        {Number(timer_request_rfq.ask_price).toFixed(5)}
                                      </span>
                                    )}
                                    {timer_request_rfq.ask_price === 0 && <span className="fx-ticker-price-value">N/A</span>}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {/* START::allocates details */}
                          {allocate_portfolio.length > 1 && (
                            <div className="allocates_div">
                              <div className="client-area">
                                {allocates_inputs.map((allocates_input, index) => (
                                  <div
                                    key={index}
                                    className={
                                      is_fees_display !== 1 || (is_commission === 1 && Number(market_id) !== 6)
                                        ? "form-main three-col"
                                        : "form-main new-form-main"
                                    }
                                  >
                                    <div className="form-group custome-select client-select-input">
                                      <label className="select-lable">Clients</label>
                                      <Select
                                        isClearable={true}
                                        styles={DropDownStyles2}
                                        menuPlacement="top"
                                        maxMenuHeight={180}
                                        placeholder="Clients"
                                        value={allocate_portfolio
                                          .filter((option: any) => option.id === allocates_input.client_id)
                                          .map((i: any) => {
                                            return {
                                              value: i.id,
                                              label: i.name,
                                            };
                                          })}
                                        // defaultValue={allocates_input.client_id}
                                        // onChange={(e: any) => renderClientFees(allocates_input.client_id, e, index)}
                                        onChange={(e: any) => {
                                          console.log("callhere", e);

                                          if (e) {
                                            renderClientFees(allocates_input.client_id, e, index, allocates_input.qty);
                                          } else {
                                            rendorNullSelect(index);
                                          }
                                        }}
                                        options={allocate_portfolio.map((option: any) => ({
                                          value: option.id,
                                          label: option.name,
                                        }))}
                                      />
                                    </div>
                                    <div className="form-group custom-input">
                                      <TextBox
                                        label={"Quantity"}
                                        value={allocates_input.qty}
                                        onChange={(e: any) => {
                                          handleFormQty(e, index, allocates_input.client_id);
                                          if (Number(e.target.value) !== 0) {
                                            setTimeout(() => {
                                              setChangeQty(e.target.value);
                                            }, 2000);
                                          }
                                        }}
                                      />
                                    </div>
                                    {is_fees_display !== 1 && (
                                      <div className="form-group custom-input">
                                        <TextBox
                                          label={"Percentage"}
                                          value={allocates_input.percentage}
                                          onChange={(e: any) => handleFormpercentage(e, index)}
                                          readonly={true}
                                        />
                                      </div>
                                    )}
                                    {is_commission === 1 && Number(market_id) !== 6 && (
                                      <div className="form-group custom-input">
                                        <TextBox
                                          label={market_id === "1" || market_id === 1 ? "Commission" : "Mark-up"}
                                          value={allocates_input.markup_fees}
                                          onChange={(e: any) => handleFormMarkupFees(e, index)}
                                        />
                                      </div>
                                    )}
                                    {Number(market_id) === 6 && loggedType === "internal" && (
                                      <>
                                        <div className="form-group custome-select">
                                          <label className="select-lable">Markup Type</label>
                                          <Select
                                            styles={DropDownStyles2}
                                            // value={"Interactive"}
                                            placeholder="Markup Type"
                                            options={markupOpt}
                                            onChange={(e: any) => {
                                              handleMarkupChange(allocates_input.client_id, e, index, allocates_input.qty);
                                              setTimeout(() => {
                                                set_is_change_markup_data(true);
                                                // if (allocates_input.markup_type) {
                                                //   dispatch(
                                                //     getClientMarkup({
                                                //       client_id: allocates_input.client_id,
                                                //       markup_type: allocates_input.markup_type,
                                                //       markup_value: allocates_input.markup_value,
                                                //     })
                                                //   );
                                                // }
                                              }, 1000);
                                            }}
                                            isSearchable={false}
                                            value={{
                                              value: allocates_input.markup_type,
                                              label: String(allocates_input.markup_type) === "abs" ? "ABS" : "BPS",
                                              // label: form.vendor,
                                            }}
                                          />
                                        </div>
                                        <div className="form-group custom-input">
                                          <TextBox
                                            label={`${String(allocates_input.markup_type) === "abs" ? "ABS" : "BPS"} value`}
                                            value={allocates_input.markup_value}
                                            onChange={(e: any) => {
                                              handleMarkupValueChange(allocates_input.client_id, e, index, allocates_input.qty);
                                            }}
                                            readonly={Number(allocates_input.client_id) === 0 ? true : false}
                                          />
                                        </div>
                                      </>
                                    )}
                                    <div className="form-group custom-input">
                                      <TextBox
                                        label={"Execution"}
                                        value={allocates_input.fees}
                                        onChange={(e: any) => handleallocatesInputFees(e, index)}
                                        readonly={loggedType === "internal" ? false : true}
                                        // readonly={Number(allocates_input.client_id) === 0 ? true : false}
                                      />
                                    </div>
                                    <div className="form-group custom-input">
                                      <TextBox
                                        label={"Settlement"}
                                        value={allocates_input.set_fees}
                                        onChange={(e: any) => handleallocatesInputSetFees(e, index)}
                                        readonly={loggedType === "internal" ? false : true}
                                        // readonly={Number(allocates_input.client_id) === 0 ? true : false}
                                      />
                                    </div>
                                    {/* </div> */}
                                    <div className="allocates-button">
                                      {index === 0 && (
                                        //  v-show="k == allocates_inputs.length - 1"
                                        <div onClick={(e: any) => add_allocate(index)}>
                                          <img src={addMore} alt="" />
                                        </div>
                                      )}
                                      {index != 0 && Object.keys(allocates_inputs).length > 1 && (
                                        // v-show="k || (!k && allocates_inputs.length > 1)"
                                        <div onClick={(e: any) => remove_allocate(index, allocates_input.client_id)}>
                                          <img src={removeOne} alt="" />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {/* {allocate_portfolio.length > 1 && (
                                <div className="rfq_allote table-button-groups p-0 d-flex justify-content-end align-items-center">
                                  {isAllowExecutionAndSettlement && (
                                    <div className="allocates_div">
                                      <div className="form-main">
                                        <div className="form-group custom-input">
                                          <TextBox
                                            label={"Execution"}
                                            value={form.allocates_input_fees}
                                            onChange={(e: any) => handleallocatesInputFees(e)}
                                          />
                                        </div>
                                        <div className="form-group custom-input">
                                          <TextBox
                                            label={"Settlement"}
                                            value={form.allocates_input_set_fees}
                                            onChange={(e: any) => handleallocatesInputSetFees(e)}
                                          />
                                        </div>
                                        <div className="extra-space"></div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )} */}
                            </div>
                          )}
                          {Number(form.vendor) === 7 && !hide_quote_btn && (
                            <Button
                              className={"w-full mx-0 modal-green-btn"}
                              type="button"
                              varient={"green"}
                              CTA={"Get Quote"}
                              handleClick={(e: any) => getFXQuote(e)}
                              disabled={getquote_disabled_confirm === true}
                            />
                          )}
                          {Number(form.vendor) === 7 && hide_quote_btn && (
                            <Button
                              className={"w-full mx-0 modal-green-btn"}
                              type="button"
                              varient={"green"}
                              CTA={confirm_btn_labl}
                              handleClick={(e: any) => submitOrderFrom(e)}
                              disabled={disabled_confirm === 1}
                            />
                          )}
                          {Number(form.vendor) !== 7 && hide_quote_btn && (
                            <Button
                              className={"w-full mx-0 modal-green-btn"}
                              type="button"
                              varient={"green"}
                              CTA={confirm_btn_labl}
                              handleClick={(e: any) => submitOrderFrom(e)}
                              disabled={disabled_confirm === 1}
                            />
                          )}

                          {/* END::allocates details */}
                        </OrderConfirmationModal>
                      )}
                    </form>
                  )}
                  {/* END::Not isRFQForm */}
                  {/* START:: Fund Form */}
                  {!isRFQForm && Number(default_security_set.market_id) === 8 && Number(default_security_set.market_id) !== 0 && (
                    <form onSubmit={onSubmit}>
                      <div className="">
                        <div className="bid-ask-group 1">
                          <div className="bid-ask-box bid-box nav-box w-full">
                            <span>NAV</span>
                            <h3>
                              {last_price_value !== 0 ? Number(last_price_value).toFixed(2) : 0} <img className="bid-icon" src={bidIcon} alt="" />
                            </h3>
                          </div>
                        </div>
                        <div className="order-form-main">
                          <div className="order-form-select-input custome-select select-disabled">
                            <label className="select-lable">Type</label>
                            <Select
                              placeholder="Type"
                              value={{
                                value: "market",
                                label: "MARKET",
                              }}
                              options={limits}
                              isSearchable={false}
                              isDisabled={true}
                              styles={DropDownStyles2}
                            />
                          </div>
                          <div className="order-form-select-input custome-select select-disabled">
                            <label className="select-lable">Time</label>
                            <Select
                              placeholder="Time"
                              value={{
                                value: "good_till_cancel",
                                label: "GTC",
                              }}
                              // onChange={(e: any) => handleChangeDay(e)}
                              options={days}
                              isSearchable={false}
                              isDisabled={true}
                              styles={DropDownStyles2}
                            />
                          </div>
                          <div className="order-form-select-input custome-select">
                            <label className="select-lable">Request For</label>
                            <Select
                              styles={DropDownStyles2}
                              placeholder="Request For"
                              value={{
                                value: form.fund_order_type,
                                label: form.fund_order_type === "amount" ? "Market value" : "Shares",
                              }}
                              onChange={(e: any) => onChangeRequestType(e)}
                              options={fund_order_types}
                              isSearchable={false}
                            />
                          </div>
                          {quantityFieldState && (
                            <div className="order-form-select-input">
                              <TextBox
                                label={qty_label}
                                value={form.quantity}
                                onChange={(e: any) => handleChangeFundQuantity(e)}
                                onKeyUp={(e: any) => updateButtonEvent(e)}
                              />
                            </div>
                          )}
                          {amountFieldState && (
                            <div className="order-form-select-input">
                              <TextBox
                                label={qty_label}
                                value={form.fund_amount}
                                onChange={(e: any) => handleChangeFundAmount(e)}
                                onKeyUp={(e: any) => updateButtonEvent(e)}
                              />
                            </div>
                          )}
                        </div>
                        <div className="order-btn-group">
                          <div className="w-full button-box">
                            <Button
                              type="submit"
                              varient={"red"}
                              CTA={"Redeem"}
                              handleClick={() => setDirection("Sell")}
                              disabled={place_order_status === "pending" || Number(last_price_value) === 0 ? true : false}
                            />

                            {form.direction === "Sell" && place_order_status === "pending" && (
                              <div className="order-btton-loader-box">
                                <img className="order-search-loader" src={WhiteLoader} alt="" />
                              </div>
                            )}
                          </div>
                          <div className="w-full button-box">
                            <Button
                              type="submit"
                              varient={"green"}
                              CTA={"Subscribe"}
                              handleClick={() => setDirection("Buy")}
                              disabled={place_order_status === "pending" || Number(last_price_value) === 0 ? true : false}
                            />
                            {form.direction === "Buy" && place_order_status === "pending" && (
                              <div className="order-btton-loader-box">
                                <img className="order-search-loader" src={WhiteLoader} alt="" />
                              </div>
                            )}
                          </div>
                          <Button
                            inputRef={(input: any) => (inputButtonElement = input)}
                            className="hidden "
                            id={formName}
                            type="submit"
                            varient={form.direction === "Buy" ? "green" : "red"}
                            CTA={form.direction}
                            handleClick={() => hiddenclickFire("Sell")}
                          />
                        </div>
                      </div>
                      {order_confirm_dialog && (
                        <OrderConfirmationModal show={order_confirm_dialog} confirmClose={confirmClose} header={"Order Confirmation"}>
                          {form.direction.toLowerCase() === "buy" && Number(default_security_set.market_id) === 8 && (
                            <FundBOrderConfirmationDetail confirm={confirm} ask_value={ask_value} />
                          )}
                          {form.direction.toLowerCase() === "sell" && Number(default_security_set.market_id) === 8 && (
                            <FundSOrderConfirmationDetail confirm={confirm} bid_value={bid_value} />
                          )}

                          {/* START::allocates details */}
                          {allocate_portfolio.length > 1 && (
                            <div className="allocates_div">
                              <div className="client-area">
                                {allocates_inputs.map((allocates_input, index) => (
                                  <div className="form-main fund-form three-col" key={index}>
                                    <div className="form-group custome-select client-select-input">
                                      <label className="select-lable">Clients</label>
                                      <Select
                                        isClearable={true}
                                        styles={DropDownStyles2}
                                        className="w-fll"
                                        placeholder="Clients"
                                        menuPlacement="top"
                                        value={allocate_portfolio
                                          .filter((option: any) => option.id === allocates_input.client_id)
                                          .map((i: any) => {
                                            return {
                                              value: i.id,
                                              label: i.name,
                                            };
                                          })}
                                        // defaultValue={allocates_input.client_id}
                                        // onChange={(e: any) => renderClientFees(allocates_input.client_id, e, index)}
                                        onChange={(e: any) => {
                                          console.log("callhere", e);
                                          if (e) {
                                            renderClientFees(allocates_input.client_id, e, index, allocates_input.qty);
                                          } else {
                                            rendorNullSelect(index);
                                          }
                                        }}
                                        options={allocate_portfolio.map((option: any) => ({
                                          value: option.id,
                                          label: option.name,
                                        }))}
                                      />
                                    </div>
                                    {quantityFieldState && (
                                      <div className="form-group custom-input">
                                        <TextBox
                                          label={"Shares"}
                                          value={allocates_input.qty}
                                          onChange={(e: any) => {
                                            handleFormQty(e, index, allocates_input.client_id);
                                            if (Number(e.target.value) !== 0) {
                                              setTimeout(() => {
                                                setChangeQty(e.target.value);
                                              }, 2000);
                                            }
                                          }}
                                        />
                                      </div>
                                    )}
                                    {amountFieldState && (
                                      <div className="form-group custom-input">
                                        <TextBox label={"Amount"} value={allocates_input.amount} onChange={(e: any) => handleFormamount(e, index)} />
                                      </div>
                                    )}

                                    {is_fees_display !== 1 && (
                                      <div className="form-group custom-input">
                                        <TextBox
                                          label={"Percentage"}
                                          value={String(allocates_input.percentage)}
                                          onChange={(e: any) => handleFormpercentage(e, index)}
                                          readonly={true}
                                        />
                                      </div>
                                    )}
                                    {is_commission === 1 && (
                                      <div className="form-group custom-input">
                                        <TextBox
                                          label={market_id === "1" || market_id === 1 ? "Commission" : "Mark-up"}
                                          value={allocates_input.markup_fees}
                                          onChange={(e: any) => handlemarkupFeeschange(e, index)}
                                        />
                                      </div>
                                    )}
                                    {/* {isAllowExecutionAndSettlement && ( */}
                                    <div className="form-group custom-input">
                                      <TextBox
                                        label={"Execution"}
                                        value={allocates_input.fees}
                                        onChange={(e: any) => handleallocatesInputFees(e, index)}
                                        readonly={loggedType === "internal" ? false : true}
                                      />
                                    </div>
                                    {/* )} */}
                                    {/* {isAllowExecutionAndSettlement && ( */}
                                    <div className="form-group custom-input">
                                      <TextBox
                                        label={"Settlement"}
                                        value={allocates_input.set_fees}
                                        onChange={(e: any) => handleallocatesInputSetFees(e, index)}
                                        readonly={loggedType === "internal" ? false : true}
                                      />
                                    </div>
                                    {/* )} */}
                                    <div className="allocates-button">
                                      {index === 0 && (
                                        //  v-show="k == allocates_inputs.length - 1"
                                        <div onClick={(e: any) => add_allocate(index)}>
                                          <img src={addMore} alt="" />
                                        </div>
                                      )}
                                      {index > 0 && Object.keys(allocates_inputs).length > 1 && (
                                        // v-show="k || (!k && allocates_inputs.length > 1)"
                                        <div onClick={(e: any) => remove_allocate(index, allocates_input.client_id)}>
                                          <img src={removeOne} alt="" />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {/* {allocate_portfolio.length > 1 && (
                                <div className="rfq_allote table-button-groups p-0 d-flex justify-content-end align-items-center">
                                  {isAllowExecutionAndSettlement && (
                                    <div className="allocates_div">
                                      <div className="form-main">
                                        <div className="form-group custom-input">
                                          <TextBox
                                            label={"Execution"}
                                            value={form.allocates_input_fees}
                                            onChange={(e: any) => handleallocatesInputFees(e)}
                                          />
                                        </div>
                                        <div className="form-group custom-input">
                                          <TextBox
                                            label={"Settlement"}
                                            value={form.allocates_input_set_fees}
                                            onChange={(e: any) => handleallocatesInputSetFees(e)}
                                          />
                                        </div>
                                        <div className="extra-space"></div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )} */}
                            </div>
                          )}
                          <Button
                            type="button"
                            varient={"green"}
                            CTA={confirm_btn_labl}
                            handleClick={(e: any) => submitOrderFrom(e)}
                            disabled={disabled_confirm === 1 ? true : false}
                          />

                          {/* END::allocates details */}
                        </OrderConfirmationModal>
                      )}
                    </form>
                  )}
                  {/* END fund form */}
                  {/* START::is RFQForm yes for bond */}
                  {isRFQForm && Number(default_security_set.market_id) === 5 && Number(default_security_set.market_id) !== 0 && (
                    <form onSubmit={onRFQSubmit}>
                      <div className="">
                        {show_bond_vendor_dropdown && loggedType === "internal" && (
                          <div className="order-form-select-input custome-select w-full mb-2">
                            <label className="select-lable">Broker</label>
                            <Select
                              styles={DropDownStylesBroker}
                              // value={"Interactive"}
                              placeholder="Broker"
                              options={bond_vendor_option}
                              onChange={(e: any) => handleBondVendorChange(e)}
                              isSearchable={false}
                              value={{
                                value: rfq_form.vendor,
                                label: getBondVendorTitle(rfq_form.vendor, "select"),
                                // label: (e: any) => getBondVendorTitle(e, rfq_form.vendor),
                                // label: form.vendor,
                              }}
                            />
                          </div>
                        )}
                        <div className="bid-ask-group 2">
                          <div className="bid-ask-box bid-box">
                            <span>BID</span>
                            <h3>
                              {Number(bid_value) === 0 ? "N/A" : bid_value}
                              {/* <img className="bid-icon" src={bidIcon} alt="" /> */}
                            </h3>
                          </div>
                          <div className="bid-ask-box ask-box">
                            <span>ASK</span>{" "}
                            <h3>
                              {/* <img className="ask-icon" src={askIcon} alt="" /> */}
                              {Number(ask_value) === 0 ? "N/A" : ask_value}
                            </h3>
                          </div>
                          <span className="bid-ask-difference">
                            {Number(ask_value) !== 0 && Number(bid_value) !== 0 ? (Number(ask_value) - Number(bid_value)).toFixed(3) : "N/A"}
                          </span>
                        </div>
                        <div className="order-form-main">
                          {rfqFormType === "bond" && (
                            <div className="order-form-select-input custome-select select-disabled">
                              <label className="select-lable">Time</label>
                              <Select
                                styles={DropDownStyles2}
                                placeholder="Time"
                                value={{
                                  value: rfq_form.day,
                                  label: rfq_form.day.toUpperCase(),
                                }}
                                isDisabled={true}
                                options={days}
                                isSearchable={false}
                              />
                            </div>
                          )}
                          <div className="order-form-select-input custome-select select-disabled">
                            <label className="select-lable">Type</label>
                            <Select
                              styles={DropDownStyles2}
                              placeholder="Type"
                              value={{
                                value: "limit",
                                label: "LIMIT",
                              }}
                              isDisabled={true}
                              // onChange={(e: any) => onChangeOrderType(e)}
                              options={limits}
                              isSearchable={false}
                            />
                          </div>
                          {rfqFormType === "bond" && (
                            <div className="order-form-select-input">
                              <TextBox
                                label={"Amount"}
                                value={rfq_form.quantity}
                                onChange={(e: any) => handleRFQChangeAmount(e)}
                                onKeyUp={(e: any) => onRFQChangeAmount(e)}
                              />
                            </div>
                          )}
                          {rfqFormType === "bond" && (
                            <div className="order-form-select-input">
                              <TextBox
                                label={"Limit Price"}
                                value={rfq_form.limit_price}
                                onChange={(e: any) => handleChangeLimitPrice(e)}
                                onKeyUp={(e: any) => onRFQChangeLimitPrice(e)}
                              />
                            </div>
                          )}
                        </div>
                        <div className="order-btn-group">
                          <div className="w-full button-box">
                            <Button
                              type="submit"
                              varient={"red"}
                              CTA={"Sell Order"}
                              disabled={loggedType === "internal" ? false : bid_value !== 0 ? false : true}
                              handleClick={() => setRFQDirection("Sell")}
                            />
                            {/* <Button type="submit" varient={"red"} CTA={"Sell Order"} disabled={isRfqBtn} handleClick={() => setRFQDirection("Sell")} /> */}
                            {rfq_form.direction === "SELL" && rfq_place_order_status === "pending" && (
                              <div className="order-btton-loader-box">
                                <img className="order-search-loader" src={WhiteLoader} alt="" />
                              </div>
                            )}
                          </div>
                          <div className="w-full button-box">
                            <Button
                              type="submit"
                              varient={"green"}
                              CTA={"Buy Order"}
                              disabled={loggedType === "internal" ? false : ask_value !== 0 ? false : true}
                              handleClick={() => setRFQDirection("Buy")}
                            />
                            {/* <Button type="submit" varient={"green"} CTA={"Buy Order"} disabled={isRfqBtn} handleClick={() => setRFQDirection("Buy")} /> */}
                            {rfq_form.direction === "BUY" && rfq_place_order_status === "pending" && (
                              <div className="order-btton-loader-box">
                                <img className="order-search-loader" src={WhiteLoader} alt="" />
                              </div>
                            )}
                          </div>
                          <Button
                            inputRef={(input: any) => (inputButtonElement = input)}
                            className="hidden "
                            id={formName}
                            type="submit"
                            varient={rfq_form.direction === "Buy" ? "green" : "red"}
                            CTA={rfq_form.direction}
                            handleClick={() => hiddenclickFire("Sell")}
                          />
                        </div>
                      </div>
                      {/* START::1st timer dialog     */}
                      {first_timer_dialog && (
                        <OrderConfirmationModal
                          show={first_timer_dialog}
                          confirmClose={(e: any) => first_timer_dialog_cancel(1)}
                          header={order_executed_lbl}
                        >
                          {is_order_placed && allocate_portfolio.length > 1 && (
                            <>
                              <div className="stream-area">
                                {rfq_form.direction === "BUY" && Number(timer_request_rfq.ask_price) !== 0 && loggedType === "internal" && (
                                  <div className="buyBrokerBox">
                                    <div className="brokerBox">
                                      <span>Broker</span>
                                      {Number(bond_quote_ask_vendor) === 4 && <p>Tradeweb</p>}
                                      {Number(bond_quote_ask_vendor) === 5 && <p>UBS</p>}
                                      {Number(bond_quote_ask_vendor) === 6 && <p>MTS</p>}
                                      {Number(bond_quote_ask_vendor) === 2 && <p>Interactive</p>}
                                      {Number(bond_quote_ask_vendor) === 9 && <p>Intesa</p>}
                                      {Number(bond_quote_ask_vendor) === 10 && <p>Bloomberg</p>}
                                      {/* <p>{getBondVendorTitle(Number(bond_quote_ask_vendor), "modal")}</p> */}
                                    </div>
                                    <div className="valueBox">
                                      <span>ASK</span>
                                      <p>{timer_request_rfq.ask_price}</p>
                                    </div>
                                    <div className="valueBox">
                                      <span>ASK SIZE</span>
                                      <p>{formatNumber(Number(bond_quote_ask_size) * 1000)}</p>
                                    </div>
                                  </div>
                                )}
                                {rfq_form.direction === "SELL" && Number(timer_request_rfq.bid_price) !== 0 && loggedType === "internal" && (
                                  <div className="sellBrokerBox">
                                    <div className="brokerBox">
                                      <span>Broker</span>
                                      {/* <p>{getBondVendorTitle(Number(bond_quote_bid_vendor), "modal")}</p> */}
                                      {Number(bond_quote_bid_vendor) === 4 && <p>Tradeweb</p>}
                                      {Number(bond_quote_bid_vendor) === 5 && <p>UBS</p>}
                                      {Number(bond_quote_bid_vendor) === 6 && <p>MTS</p>}
                                      {Number(bond_quote_bid_vendor) === 2 && <p>Interactive</p>}
                                      {Number(bond_quote_bid_vendor) === 9 && <p>Intesa</p>}
                                      {Number(bond_quote_bid_vendor) === 10 && <p>Bloomberg</p>}
                                    </div>
                                    <div className="valueBox">
                                      <span>BID</span>
                                      <p>{timer_request_rfq.bid_price}</p>
                                    </div>
                                    <div className="valueBox">
                                      <span>BID SIZE</span>
                                      <p>{bond_quote_bid_size * 1000}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="allocates_div">
                                <div className="client-area">
                                  {allocates_inputs.map((allocates_input, index) => (
                                    <div className="form-main" key={index}>
                                      <div className="form-group custome-select">
                                        <label className="select-lable">Clients</label>
                                        <Select
                                          isClearable={true}
                                          styles={DropDownStyles2}
                                          placeholder="Clients"
                                          menuPlacement="top"
                                          value={allocate_portfolio
                                            .filter((option: any) => option.id === allocates_input.client_id)
                                            .map((i: any) => {
                                              return {
                                                value: i.id,
                                                label: i.name,
                                              };
                                            })}
                                          // defaultValue={allocates_input.client_id}
                                          // onChange={(e: any) => renderClientFees(allocates_input.client_id, e, index)}
                                          onChange={(e: any) => {
                                            console.log("callhere", e);
                                            if (e) {
                                              renderClientFees(allocates_input.client_id, e, index, allocates_input.qty);
                                            } else {
                                              rendorNullSelect(index);
                                            }
                                          }}
                                          options={allocate_portfolio.map((option: any) => ({
                                            value: option.id,
                                            label: option.name,
                                          }))}
                                        />
                                      </div>
                                      <div className="form-group custom-input">
                                        <TextBox
                                          label={"Quantity"}
                                          value={allocates_input.qty}
                                          onChange={(e: any) => {
                                            handleRfqQty(e, index, allocates_input.client_id);
                                            if (Number(e.target.value) !== 0) {
                                              setTimeout(() => {
                                                setChangeQty(e.target.value);
                                              }, 2000);
                                            }
                                          }}
                                        />
                                      </div>
                                      {/* {isAllowExecutionAndSettlement && ( */}
                                      <div className="form-group custom-input">
                                        <TextBox
                                          label={"Execution"}
                                          value={allocates_input.fees}
                                          onChange={(e: any) => handleallocatesInputFees(e, index)}
                                          readonly={loggedType === "internal" ? false : true}
                                        />
                                      </div>
                                      {/* )} */}
                                      {/* {isAllowExecutionAndSettlement && ( */}
                                      <div className="form-group custom-input">
                                        <TextBox
                                          label={"Settlement"}
                                          value={allocates_input.set_fees}
                                          onChange={(e: any) => handleallocatesInputSetFees(e, index)}
                                          readonly={loggedType === "internal" ? false : true}
                                        />
                                      </div>
                                      {/* )} */}
                                      <div className="allocates-button">
                                        {index === 0 && (
                                          <div onClick={(e: any) => add_allocate(index)}>
                                            <img src={addMore} alt="" />
                                          </div>
                                        )}
                                        {index > 0 && Object.keys(allocates_inputs).length > 1 && (
                                          <div onClick={(e: any) => remove_allocate(index, allocates_input.client_id)}>
                                            <img src={removeOne} alt="" />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>

                                {/* END::1st timer dialog */}
                              </div>
                            </>
                          )}
                          {is_order_placed && (
                            <Button
                              className={"w-full mx-0 modal-green-btn"}
                              type="button"
                              varient={"green"}
                              CTA={confirm_btn_labl}
                              handleClick={(e: any) => submitOrderFrom(e)}
                              disabled={disabled_confirm === 1}
                            />
                          )}
                          {!is_order_placed && (
                            <>
                              <div className="stream-area">
                                {rfq_form.direction === "BUY" && Number(timer_request_rfq.ask_price) !== 0 && loggedType === "internal" && (
                                  <div className="buyBrokerBox">
                                    <div className="brokerBox">
                                      <span>Broker</span>
                                      {Number(bond_quote_ask_vendor) === 4 && <p>Tradeweb</p>}
                                      {Number(bond_quote_ask_vendor) === 5 && <p>UBS</p>}
                                      {Number(bond_quote_ask_vendor) === 6 && <p>MTS</p>}
                                      {Number(bond_quote_ask_vendor) === 2 && <p>Interactive</p>}
                                      {Number(bond_quote_ask_vendor) === 9 && <p>Intesa</p>}
                                      {Number(bond_quote_ask_vendor) === 10 && <p>Bloomberg</p>}
                                      {/* <p>{getBondVendorTitle(Number(bond_quote_ask_vendor), "modal")}</p> */}
                                    </div>
                                    <div className="valueBox">
                                      <span>ASK</span>
                                      <p>{timer_request_rfq.ask_price}</p>
                                    </div>
                                    <div className="valueBox">
                                      <span>ASK SIZE</span>
                                      <p>{formatNumber(Number(bond_quote_ask_size) * 1000)}</p>
                                    </div>
                                  </div>
                                )}
                                {rfq_form.direction === "SELL" && Number(timer_request_rfq.bid_price) !== 0 && loggedType === "internal" && (
                                  <div className="sellBrokerBox">
                                    <div className="brokerBox">
                                      <span>Broker</span>
                                      {/* <p>{getBondVendorTitle(Number(bond_quote_bid_vendor), "modal")}</p> */}
                                      {Number(bond_quote_bid_vendor) === 4 && <p>Tradeweb</p>}
                                      {Number(bond_quote_bid_vendor) === 5 && <p>UBS</p>}
                                      {Number(bond_quote_bid_vendor) === 6 && <p>MTS</p>}
                                      {Number(bond_quote_bid_vendor) === 2 && <p>Interactive</p>}
                                      {Number(bond_quote_bid_vendor) === 9 && <p>Intesa</p>}
                                      {Number(bond_quote_bid_vendor) === 10 && <p>Bloomberg</p>}
                                    </div>
                                    <div className="valueBox">
                                      <span>BID</span>
                                      <p>{timer_request_rfq.bid_price}</p>
                                    </div>
                                    <div className="valueBox">
                                      <span>BID SIZE</span>
                                      <p>{formatNumber(Number(bond_quote_bid_size) * 1000)}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="ticker-detail-wrap first_timer_dialog order-form-group">
                                <div className="offer-wanted-wrap">
                                  <div className={isRfqBtn ? "disabled-box offer-wanted-box" : "offer-wanted-box"}>
                                    <span className="ticker-price-text">{requesting_quotes_title}</span>
                                    {is_tradeweb_received && <span className="ticker-price-value origin-timer">{update_rfq_timer}</span>}
                                    {!is_tradeweb_received && idle_first_rfq_timer !== 10 && (
                                      <span className="ticker-price-value without-10">00:0{idle_first_rfq_timer}</span>
                                    )}
                                    {!is_tradeweb_received && idle_first_rfq_timer === 10 && (
                                      <span className="ticker-price-value with-10">00:{idle_first_rfq_timer}</span>
                                    )}
                                  </div>
                                  {rfq_form.direction === "SELL" && (
                                    <div className={isRfqBtn ? "disabled-box offer-wanted-box" : "offer-wanted-box"}>
                                      <span className="ticker-price-text">Bid</span>
                                      {timer_request_rfq.bid_price !== 0 && (
                                        <span className={add_blink ? "is_blinking sell-color" : "sell-color"}>{timer_request_rfq.bid_price}</span>
                                      )}
                                      {timer_request_rfq.bid_price === 0 && <span className="ticker-price-value">N/A</span>}
                                    </div>
                                  )}
                                  {rfq_form.direction === "BUY" && (
                                    <div className={isRfqBtn ? "disabled-box offer-wanted-box" : "offer-wanted-box"}>
                                      <span className="ticker-price-text">Ask</span>
                                      {timer_request_rfq.ask_price !== 0 && (
                                        <span className={add_blink ? "is_blinking buy-color" : "buy-color"}>{timer_request_rfq.ask_price}</span>
                                      )}
                                      {timer_request_rfq.ask_price === 0 && <span className="ticker-price-value">N/A</span>}
                                    </div>
                                  )}
                                </div>
                                {zero_price_link && !is_third_link_open && !is_tradeweb_received && (
                                  <div className="zero_price_link">
                                    {!is_zero_link_click && idle_first_rfq_timer === 0 && (
                                      <>
                                        Pricing within the Limit not available.{" "}
                                        <span
                                          className="as_link"
                                          onClick={(e: any) => handleNoPriceClickEvent(rfq_form.direction, rfq_form.quantity, rfq_form.limit_price)}
                                        >
                                          Remove Limit?
                                        </span>
                                      </>
                                    )}
                                    {/* {is_zero_link_click && <span className="as_link with_disable">Remove Limit?</span>} */}
                                  </div>
                                )}
                                {is_third_link_open && !is_tradeweb_received && (
                                  <div className="zero_price_link">
                                    {!is_zero_link_click && idle_first_rfq_timer === 0 && (
                                      <>
                                        No price available.{" "}
                                        <span
                                          className="as_link"
                                          onClick={(e: any) => handleNoPriceClickEvent(rfq_form.direction, rfq_form.quantity, rfq_form.limit_price)}
                                        >
                                          Try again.
                                        </span>
                                      </>
                                    )}
                                    {/* {is_zero_link_click && <span className="as_link with_disable">Please try again</span>} */}
                                  </div>
                                )}
                                <div className="offer-wanted-btn-group">
                                  {disableRFQBtn && !is_zero_link_click && (
                                    <Button
                                      type="button"
                                      varient={""}
                                      CTA={"Pass"}
                                      className=" pass-btn"
                                      handleClick={(e: any) => first_timer_dialog_cancel(1)}
                                    />
                                  )}
                                  {disableRFQBtn && rfq_form.direction === "SELL" && !is_zero_link_click && (
                                    <Button
                                      className={is_btn_txt_change ? " loader-in-btn" : ""}
                                      type="submit"
                                      varient={"red"}
                                      CTA={hit_btn_label}
                                      handleClick={(e: any) => openconfirmbox(e)}
                                      disabled={isRfqBtn}
                                    />
                                  )}
                                  {disableRFQBtn && rfq_form.direction === "BUY" && !is_zero_link_click && (
                                    <Button
                                      className={is_btn_txt_change ? " loader-in-btn" : ""}
                                      type="submit"
                                      varient={"green"}
                                      CTA={lift_btn_label}
                                      handleClick={(e: any) => openconfirmbox(e)}
                                      disabled={isRfqBtn}
                                    />
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </OrderConfirmationModal>
                      )}
                      {/* END::1st timer dialog      */}
                      {/* START::Order confirm dialog */}
                      {order_confirm_dialog && (
                        <OrderConfirmationModal
                          Componentref={OrderConfirmationModalref}
                          show={order_confirm_dialog}
                          confirmClose={confirmClose}
                          header={"Order Confirmation"}
                        >
                          <div>
                            <div className="stream-area">
                              {rfq_form.direction === "BUY" && loggedType === "internal" && (
                                <div className="buyBrokerBox">
                                  <div className="brokerBox">
                                    <span>Broker</span>

                                    <p>{getBondVendorTitle(rfq_form.vendor, "modal")}</p>
                                  </div>
                                  <div className="valueBox">
                                    <span>ASK</span>
                                    <p>{ask_value}</p>
                                  </div>
                                  <div className="valueBox">
                                    <span>ASK SIZE</span>
                                    <p>{bond_quote_ask_size * 1000}</p>
                                  </div>
                                </div>
                              )}
                              {rfq_form.direction === "SELL" && loggedType === "internal" && (
                                <div className="sellBrokerBox">
                                  <div className="brokerBox">
                                    <span>Broker</span>
                                    <p>{getBondVendorTitle(rfq_form.vendor, "modal")}</p>
                                  </div>
                                  <div className="valueBox">
                                    <span>BID</span>
                                    <p>{bid_value}</p>
                                  </div>
                                  <div className="valueBox">
                                    <span>BID SIZE</span>
                                    <p>{bond_quote_bid_size * 1000}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                            {rfq_form.direction.toLowerCase() === "buy" && Number(default_security_set.market_id) === 5 && (
                              <BondBOrderConfirmationDetail confirm={confirm} ask_value={ask_value} principal_amount={principal_amount} />
                            )}
                            {rfq_form.direction.toLowerCase() === "sell" && Number(default_security_set.market_id) === 5 && (
                              <BondSOrderConfirmationDetail confirm={confirm} bid_value={bid_value} principal_amount={principal_amount} />
                            )}
                          </div>
                          {/* START::allocates details */}
                          {allocate_portfolio.length > 1 && (
                            <div className="allocates_div">
                              {allocates_inputs.map((allocates_input, index) => (
                                <div
                                  key={index}
                                  className={is_fees_display !== 1 || is_commission === 1 ? "form-main three-col" : "form-main new-form-main"}
                                >
                                  <div className="form-group custome-select client-select-input">
                                    <label className="select-lable">Clients</label>
                                    <Select
                                      isClearable={true}
                                      styles={DropDownStyles2}
                                      menuPlacement="top"
                                      maxMenuHeight={180}
                                      placeholder="Clients"
                                      value={allocate_portfolio
                                        .filter((option: any) => option.id === allocates_input.client_id)
                                        .map((i: any) => {
                                          return {
                                            value: i.id,
                                            label: i.name,
                                          };
                                        })}
                                      // defaultValue={allocates_input.client_id}
                                      // onChange={(e: any) => renderClientFees(allocates_input.client_id, e, index)}
                                      onChange={(e: any) => {
                                        console.log("callhere", e);
                                        console.log("callhere", index);
                                        if (e) {
                                          renderClientFees(allocates_input.client_id, e, index, allocates_input.qty);
                                        } else {
                                          rendorNullSelect(index);
                                        }
                                      }}
                                      options={allocate_portfolio.map((option: any) => ({
                                        value: option.id,
                                        label: option.name,
                                      }))}
                                    />
                                  </div>
                                  <div className="form-group custom-input">
                                    <TextBox
                                      label={`Quantity`}
                                      value={allocates_input.qty}
                                      onChange={(e: any) => {
                                        handleRfqQty(e, index, allocates_input.client_id);
                                        if (Number(e.target.value) !== 0) {
                                          setTimeout(() => {
                                            setChangeQty(e.target.value);
                                          }, 2000);
                                        }
                                      }}
                                    />
                                  </div>
                                  {is_fees_display !== 1 && (
                                    <div className="form-group custom-input">
                                      <TextBox
                                        label={"Percentage"}
                                        value={allocates_input.percentage}
                                        onChange={(e: any) => handleRFQFormpercentage(e, index)}
                                        readonly={true}
                                      />
                                    </div>
                                  )}
                                  {is_commission === 1 && (
                                    <div className="form-group custom-input">
                                      <TextBox
                                        label={market_id === "1" || market_id === 1 ? "Commission" : "Mark-up"}
                                        value={allocates_input.markup_fees}
                                        onChange={(e: any) => handleRFQFormMarkupFees(e, index)}
                                      />
                                    </div>
                                  )}
                                  {/* </div> */}
                                  {/* {isAllowExecutionAndSettlement && ( */}
                                  <div className="form-group custom-input">
                                    <TextBox
                                      label={"Execution"}
                                      value={allocates_input.fees}
                                      onChange={(e: any) => handleallocatesInputFees(e, index)}
                                      readonly={loggedType === "internal" ? false : true}
                                    />
                                  </div>
                                  {/* )} */}
                                  {/* {isAllowExecutionAndSettlement && ( */}
                                  <div className="form-group custom-input">
                                    <TextBox
                                      label={"Settlement"}
                                      value={allocates_input.set_fees}
                                      onChange={(e: any) => handleallocatesInputSetFees(e, index)}
                                      readonly={loggedType === "internal" ? false : true}
                                    />
                                  </div>
                                  {/* )} */}
                                  <div className="allocates-button">
                                    {index === 0 && (
                                      <div onClick={(e: any) => add_allocate(index)}>
                                        <img src={addMore} alt="" />
                                      </div>
                                    )}
                                    {index > 0 && Object.keys(allocates_inputs).length > 1 && (
                                      <div onClick={(e: any) => remove_allocate(index, allocates_input.client_id)}>
                                        <img src={removeOne} alt="" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                              {allocate_portfolio.length > 1 && (
                                <div className="rfq_allote table-button-groups p-0 d-flex justify-content-end align-items-center">
                                  {/* {isAllowExecutionAndSettlement && (
                                    <div className="allocates_div">
                                      <div className="form-main">
                                        <div className="form-group custom-input">
                                          <TextBox
                                            label={"Execution"}
                                            value={rfq_form.allocates_input_fees}
                                            onChange={(e: any) => handleallocatesInputFees(e)}
                                          />
                                        </div>
                                        <div className="form-group custom-input">
                                          <TextBox
                                            label={"Settlement"}
                                            value={rfq_form.allocates_input_set_fees}
                                            onChange={(e: any) => handleallocatesInputSetFees(e)}
                                          />
                                        </div>
                                        <div className="extra-space"></div>
                                      </div>
                                    </div>
                                  )} */}
                                </div>
                              )}
                            </div>
                          )}
                          <Button
                            className={"w-full mx-0 modal-green-btn"}
                            type="button"
                            varient={"green"}
                            CTA={confirm_btn_labl}
                            handleClick={(e: any) => submitOrderFrom(e)}
                            disabled={
                              loggedType === "internal"
                                ? false
                                : rfq_form.direction === "SELL" && bid_value !== 0
                                ? false
                                : rfq_form.direction === "BUY" && ask_value !== 0
                                ? false
                                : true
                            }
                          />
                          {/* END::allocates details */}
                        </OrderConfirmationModal>
                      )}
                      {/* END:: Order confirm dialog */}
                    </form>
                  )}
                  {/* END::is RFQForm yes  */}
                </div>
              ) : (
                <InformationWindow refProp={ref} />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
export default OrderSection;
